import React, { useState, useMemo } from "react";
import { TextField } from "@mui/material";
import { createGermanNumberString, parseGermanNumberString } from "../utils/formatting";
import { useDebounce } from "../utils/debounce";

/**
 * Renders an integer input field with validation and debounce functionality.
 *
 * This component is a wrapper around the Material-UI TextField component, designed to handle integer inputs.
 * It includes validation to ensure the input is an integer and uses debouncing to limit the rate at which
 * input validation is performed. This is particularly useful for reducing the number of validations triggered
 * by continuous typing.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.value - The value object for the input, containing 'display' and 'real' properties.
 * @param {Function} props.onChange - The function to call when the input value changes.
 * @param {Function} props.validate - The function to validate the input value. Should return a boolean.
 * @param {boolean} props.error - The error state of the input.
 * @param {Function} props.setError - The function to set the error state of the input.
 * @param {string} [props.thousandsSeparator] - Optional thousands separator for formatting the display value.
 * @returns {JSX.Element} A TextField component tailored for integer input.
 *
 * @example
 * <IntegerInput
 *   value={{ display: '123', real: 123 }}
 *   onChange={handleChange}
 *   validate={(value) => value >= 0}
 *   error={errorState}
 *   setError={setErrorState}
 * />
 */
export function IntegerInput(props) {
    const [helperText, setHelperText] = useState("");

    const validate = () => {
        if (!Number.isInteger(props.value.real)) {
            props.setError(true);
            setHelperText("Eingabe muss eine Zahl sein.");
        } else {
            props.setError(!props.validate(props.value.real));
            setHelperText("");
        }
    };

    const debouncedValidate = useDebounce(() => {
        validate();
    });

    const handleBlur = (event) => {
        if (validate()) {
            var parsedValue = Number(event.target.value);
            props.onChange({
                display: createGermanNumberString(parsedValue, props.thousandsSeparator),
                real: parsedValue,
            });
        }
    };

    const handleChange = (event) => {
        var parsedValue = Number(event.target.value);
        props.onChange({ display: event.target.value, real: parsedValue });
        debouncedValidate();
    };

    return (
        <TextField
            {...props}
            error={props.error}
            helperText={props.error ? helperText : ""}
            value={props.value?.display}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
        />
    );
}

/**
 * Renders an integer input field with validation, debounce functionality, and bounded value constraints.
 *
 * This component extends the basic integer input functionality by adding upper and lower bounds to the value.
 * It ensures that the input value stays within the specified range. The component is a wrapper around the
 * Material-UI TextField component and includes validation to ensure the input is an integer. It uses debouncing
 * to limit the rate at which input validation is performed, which is useful for reducing validations triggered
 * by continuous typing.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.value - The value object for the input, containing 'display' and 'real' properties.
 * @param {Function} props.onChange - The function to call when the input value changes.
 * @param {Function} props.validate - The function to validate the input value. Should return a boolean.
 * @param {boolean} props.error - The error state of the input.
 * @param {Function} props.setError - The function to set the error state of the input.
 * @param {number} props.minbound - The minimum bound for the input value.
 * @param {number} props.maxbound - The maximum bound for the input value.
 * @param {string} [props.thousandsSeparator] - Optional thousands separator for formatting the display value.
 * @returns {JSX.Element} A TextField component tailored for bounded integer input.
 *
 * @example
 * <BoundedIntegerInput
 *   value={{ display: '123', real: 123 }}
 *   onChange={handleChange}
 *   validate={(value) => value >= 0}
 *   error={errorState}
 *   setError={setErrorState}
 *   minbound={0}
 *   maxbound={100}
 * />
 */
export function BoundedIntegerInput(props) {
    const [helperText, setHelperText] = useState("");

    const validate = () => {
        if (!Number.isInteger(props.value.real)) {
            props.setError(true);
            setHelperText("Eingabe muss eine Zahl sein.");
        } else {
            props.setError(!props.validate(props.value.real));
            setHelperText("");
        }
    };

    const debouncedValidate = useDebounce(() => {
        validate();
    });

    const handleBlur = (event) => {
        if (validate()) {
            var parsedValue = Number(event.target.value);
            var boundedValue = Math.max(Math.min(props.maxbound, parsedValue), props.minbound);
            props.onChange({
                display: createGermanNumberString(boundedValue, props.thousandsSeparator),
                real: boundedValue,
            });
        }
    };

    const handleChange = (event) => {
        var parsedValue = Number(event.target.value);
        props.onChange({ display: event.target.value, real: parsedValue });
        debouncedValidate();
    };

    return (
        <TextField
            {...props}
            error={props.error}
            helperText={props.error ? helperText : ""}
            value={props.value?.display}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
        />
    );
}

/**
 * Renders a floating-point input field with validation, debounce functionality, and custom formatting.
 *
 * This component is designed for floating-point number inputs and includes validation to ensure the input
 * is a valid number. It uses debouncing to limit the rate at which input validation is performed, which is
 * useful for reducing validations triggered by continuous typing. The component also supports custom number
 * formatting, specifically German number formatting in this case. It is a wrapper around the Material-UI
 * TextField component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.value - The value object for the input, containing 'display' and 'real' properties.
 * @param {Function} props.onChange - The function to call when the input value changes.
 * @param {Function} props.validate - The function to validate the input value. Should return a boolean.
 * @param {boolean} props.error - The error state of the input.
 * @param {Function} props.setError - The function to set the error state of the input.
 * @param {Function} [props.onBlur] - Optional function to call when the input loses focus.
 * @returns {JSX.Element} A TextField component tailored for floating-point number input.
 *
 * @example
 * <FloatInput
 *   value={{ display: '123,45', real: 123.45 }}
 *   onChange={handleChange}
 *   validate={(value) => value >= 0}
 *   error={errorState}
 *   setError={setErrorState}
 * />
 */
export function FloatInput(props = { thousandsSeparator: true, decimalPlaces: 3 }) {
    const [helperText, setHelperText] = useState("");

    const validate = () => {
        if (isNaN(props.value.real)) {
            props.setError(true);
            setHelperText("Eingabe muss eine Zahl sein.");
            return false;
        } else {
            setHelperText("");
            var validation_success = props.validate(props.value.real);
            props.setError(!validation_success);
            return validation_success;
        }
    };

    const debouncedValidate = useDebounce(() => {
        validate();
    });

    const handleBlur = (event) => {
        if (validate()) {
            props.onChange({
                ...props.value,
                display: createGermanNumberString(props.value.real, props.thousandsSeparator, props.decimalPlaces),
            });
        }
        if (props.onBlur) {
            props.onBlur(event);
        }
    };

    const handleChange = (event) => {
        var parsedValue = parseGermanNumberString(event.target.value);
        props.onChange({ display: event.target.value, real: parsedValue });
        debouncedValidate();
    };

    return (
        <TextField
            {...props}
            error={props.error}
            helperText={props.error ? helperText : ""}
            value={props.value?.display}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
}

/**
 * Renders a TextField with validation and debounce functionality.
 *
 * This component is a wrapper around the Material-UI TextField component, adding validation and debounce
 * capabilities. It triggers validation on value change and when the input loses focus. The validation logic
 * is provided by the parent component through the `validate` prop. Debouncing is used to limit the rate of
 * validation checks, which is useful for reducing validations triggered by continuous typing.
 *
 * @param {Object} props - The component props.
 * @param {any} props.value - The current value of the input.
 * @param {Function} props.onChange - The function to call when the input value changes.
 * @param {Function} props.validate - The function to validate the input value. Should return a boolean.
 * @param {boolean} props.error - The error state of the input.
 * @param {Function} props.setError - The function to set the error state of the input.
 * @param {string} [props.errorText] - Optional text to display when there is an error.
 * @returns {JSX.Element} A TextField component with validation and debounce functionality.
 *
 * @example
 * <ValidatedTextField
 *   value={inputValue}
 *   onChange={(e) => setInputValue(e.target.value)}
 *   validate={(value) => value.length > 0}
 *   error={errorState}
 *   setError={setErrorState}
 *   errorText="This field is required"
 * />
 */
export function ValidatedTextField(props) {
    const validate = () => {
        props.setError(!props.validate(props.value));
    };

    const debouncedValidate = useDebounce(() => {
        validate();
    });

    const handleBlur = () => {
        validate();
    };

    const handleChange = (event) => {
        props.onChange(event);
        debouncedValidate();
    };

    return (
        <TextField
            {...props}
            error={props.error}
            helperText={props.error ? props.errorText : ""}
            value={props.value}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
}
