import React, { useState, useEffect, useCallback } from "react";
import api_client from "../../services/http-common";
import EnhancedTable from "../../components/SortTable";
import LazyTextField from "../../components/LazyTextfield";
import {
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Stack,
    Paper,
    Typography,
    Box,
    Grid,
    Fab,
    Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import SouthIcon from "@mui/icons-material/South";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CustomTooltip } from "../../components/CustomTooltip";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { SimpleMarkerMap } from "../../components/Maps/SimpleMarkerMap";
import { ResultTableProvider } from "../../contexts/ResultTableContext";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const ClimateFactorMissingIcon = (
    <CustomTooltip title="Für diese Kalkulation konnte keine Klimabereinigung der erwarteten Verbrauchswerte vorgenommen werden.">
        <ErrorOutlineIcon sx={{ fontSize: 15, color: "#ff9e17", mr: -1 }} />
    </CustomTooltip>
);

/**
 * It takes an indicator and an expected consumption, and returns a color
 * @param indicator - the value of the indicator you want to color
 * @param expected_consumption - the expected consumption of the user.
 * @returns returns a color as hexadecimal.
 */
const colorScale = (indicator, expected_consumption) => {
    var perc = indicator / expected_consumption;
    perc = Math.max(perc, -1);
    perc = Math.min(perc, 1);
    return pickHex(perc);
};

/* A gradient for the color scale. */
var gradient = [
    [-1, [220, 20, 60]],
    [-0.5, [255, 165, 0]],
    [0, [255, 245, 80]],
    [0.5, [173, 255, 47]],
    [1, [50, 205, 50]],
];

/**
 * It converts a number to a hexadecimal string.
 * @param c - The color value to convert.
 * @returns A string of the hexadecimal value of the color.
 */
function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

/**
 * It takes three numbers, one for red, one for green, and one for blue, and returns a string that is a
 * hexadecimal representation of those numbers
 * @param r - The red color value
 * @param g - The green value of the color.
 * @param b - The blue value of the color
 * @returns The hexadecimal value of the color.
 */
function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

/**
 * It takes a weight value between 0 and 1, and returns a hex color value that is a weighted average of
 * the colors in the gradient array
 * @param weight - the weight of the color, between 0 and 1
 * @returns A hex color code.
 */
function pickHex(weight) {
    var colors = [gradient[0], gradient[1]];
    for (var i = 1; i < gradient.length; i++) {
        if ((weight < gradient[i][0]) | (gradient[i][0] === 1)) {
            colors[0] = gradient[i - 1];
            colors[1] = gradient[i];
            break;
        }
    }
    const color1 = colors[0];
    const color2 = colors[1];

    var w2 = (weight - color1[0]) / (color2[0] - color1[0]);
    var w1 = 1 - w2;
    var r = Math.round(color1[1][0] * w1 + color2[1][0] * w2);
    var g = Math.round(color1[1][1] * w1 + color2[1][1] * w2);
    var b = Math.round(color1[1][2] * w1 + color2[1][2] * w2);
    return rgbToHex(r, g, b);
}

/**
 * Results component for displaying building performance data in a table format.
 *
 * This component fetches and displays data related to building energy efficiency, CO2 emissions, and other performance indicators.
 * It allows users to filter and sort data based on various criteria such as year, building ID, and performance metrics.
 *
 * States:
 * - data: Array of building data to be displayed in the table.
 * - dataYear: Selected year for which the data is displayed.
 * - header: Object defining the table headers and corresponding data keys.
 * - sumData: Aggregated data for display in the table summary.
 * - years: Array of available years for filtering the data.
 * - buildingFilter: Filter value for buildings.
 * - yearFilter: Selected year for filtering the data.
 *
 * The component provides interactive elements like dropdowns and text fields for filtering and a table for data presentation.
 *
 * Returns:
 *   ReactElement: The rendered component with data filtering controls and a table displaying the results.
 */
const Results = () => {
    const [data, setData] = useState([]);
    const [dataYear, setDataYear] = useState("");
    const header = {
        building_id: "int_id",
        building_ext_id: "ID",
        building_address: "Straße und Hausnummer",
        building_city: "Ort",
        result_category: "",
        energy_efficiency_class: "Energie-effizienz-klasse (Verbrauch)",
        expected_energy_efficiency_class: "Erwartete Energie-effizienz-klasse",
        performance_indicator_roomheating: "Performance Indikator Raumwärme [kWh/m²]",
        expected_consumption_roomheating_per_sqm: "Erwarteter EE-Verbrauch Raum-wärme [kWh/m²]",
        total_gross_energy_consumption: "Endenergie-verbrauch [kWh]",
        total_gross_energy_consumption_per_sqm: "Endenergie-verbrauch pro m² [kWh/m²]",
        total_co2_emissions: "CO\u2082 Emissionen [kg]",
        total_co2_emissions_per_sqm: "CO\u2082 Emissionen pro m² [kg/m²]",
        co2_costs_landlord: "CO\u2082 Kosten für Vermieter [EUR]",
        co2_costs_savings_potential_landlord: "CO\u2082 Kosten Einspar-potential Vermieter [EUR]",
        total_pv_co2_savings: "CO\u2082 Einsparung PV [kg]",
        // total_pv_co2_savings_per_sqm: "CO\u2082 Einsparungen PV pro m² [kg/m²]",
    };
    const [sumData, setSumData] = useState();
    const [mapData, setMapData] = useState([]);
    const [years, setYears] = useState([]);
    const [buildingFilter, setBuildingFilter] = useState("");
    const [yearFilter, setYearFilter] = useState("Aktuellstes Jahr");
    const [fetchingResultData, setFetchingResultData] = useState(true);
    const [displayMap, setDisplayMap] = useState(true);

    const fetchData = useCallback(
        async ({ building_filter = buildingFilter, year = yearFilter } = {}) => {
            let reqYear = year;
            if (year === "Aktuellstes Jahr") {
                reqYear = null;
            }
            const result = await api_client.post(`/building-results/`, {
                building_filter: building_filter,
                year: reqYear,
            });

            const resp = await result.data;
            if (!resp.data.length > 0) {
                setFetchingResultData(false);
            }

            // map resultcategory icons
            var data = mapResultCategoryIcons(await result.data.data);

            // set data for map
            setMapData(createMapData(data));

            // set data for table
            setData(removeAllGeoData(data));
            setSumData(mapResultCategoryIcon(removeGeoData(await result.data.sum_data)));

            // setup choices for year dropdown
            if (years.length === 0) {
                setYears(await result.data.available_years);
            }
            setDataYear(await result.data.data_year);
            // put minimal building list into local storage (only if we didnt filter the buildings)
            if (!building_filter && !reqYear) {
                let buildings = Object.values(await result.data.data).map((row) => {
                    return { id: row.building_id, address: row.building_address };
                });
                localStorage.setItem("buildingsdata", JSON.stringify(buildings));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    function createMapData(resultList) {
        var mapData = [];
        var mapDataElement = {};
        resultList.forEach((elem) => {
            mapDataElement = createMapDataElement(elem);
            if (mapDataElement) {
                mapData.push(mapDataElement);
            }
        });
        return mapData;
    }

    function createMapDataElement(result) {
        if (
            !result.building_coordinates ||
            !result.building_coordinates.latitude ||
            !result.building_coordinates.longitude
        ) {
            return null;
        } else {
            return {
                position: [result.building_coordinates.latitude, result.building_coordinates.longitude],
                id: result.building_id,
                address: result.building_address,
                color: {
                    pi: colorScale(
                        result.performance_indicator_roomheating,
                        result.expected_consumption_roomheating_per_sqm
                    ),
                    eec: "#" + result.energy_efficiency_class.color,
                    eeec: "#" + result.expected_energy_efficiency_class.color,
                },
            };
        }
    }

    function removeAllGeoData(resultList) {
        return resultList.map((elem) => {
            return removeGeoData(elem);
        });
    }

    function removeGeoData(result) {
        delete result.building_coordinates;
        delete result.building_polygon;
        return result;
    }

    function mapResultCategoryIcons(resultList) {
        return resultList.map((elem) => {
            return mapResultCategoryIcon(elem);
        });
    }

    function mapResultCategoryIcon(result) {
        switch (result.result_category) {
            case "CRITICAL":
                if (!result.climate_factor_included) {
                    result.result_category = (
                        <>
                            {ClimateFactorMissingIcon}
                            <DangerousOutlinedIcon sx={{ fontSize: 25, color: "#ff1745", mt: -1 }} />
                        </>
                    );
                } else {
                    result.result_category = <DangerousOutlinedIcon sx={{ fontSize: 25, color: "#ff1745" }} />;
                }
                break;
            case "BAD":
                if (!result.climate_factor_included) {
                    result.result_category = (
                        <>
                            {ClimateFactorMissingIcon}
                            <WarningAmberOutlinedIcon sx={{ fontSize: 25, color: "#ff9e17", mt: -1 }} />
                        </>
                    );
                } else {
                    result.result_category = <WarningAmberOutlinedIcon sx={{ fontSize: 25, color: "#ff9e17" }} />;
                }
                break;
            case "NORMAL":
                if (!result.climate_factor_included) {
                    result.result_category = (
                        <>
                            {ClimateFactorMissingIcon}
                            <ThumbUpAltOutlinedIcon sx={{ fontSize: 25, color: "#49ad2d", mt: -1 }} />
                        </>
                    );
                } else {
                    result.result_category = <ThumbUpAltOutlinedIcon sx={{ fontSize: 25, color: "#49ad2d" }} />;
                }
                break;
            case "GOOD":
                if (!result.climate_factor_included) {
                    result.result_category = (
                        <>
                            {ClimateFactorMissingIcon}
                            <SouthIcon sx={{ fontSize: 25, color: "#032C58", mt: -1 }} />
                        </>
                    );
                } else {
                    result.result_category = <SouthIcon sx={{ fontSize: 25, color: "#032C58" }} />;
                }
                break;
            default:
                result.result_category = "";
                break;
        }
        delete result.climate_factor_included;
        return result;
    }

    /**
     * It's a dropdown menu that allows the user to select a year
     * @param props - {
     * @returns A React component.
     */
    const Dropdown = (props) => {
        const handleChange = (event) => {
            setYearFilter(event.target.value);
            // fetchData({ year: event.target.value });
        };

        return (
            <FormControl sx={props.sx}>
                <InputLabel sx={{ ...props.sx, mt: props.sx.mt + 0.3 }}>Jahr auswählen</InputLabel>
                <Select
                    {...props}
                    value={yearFilter}
                    onChange={handleChange}
                    label="Jahr auswählen"
                    defaultValue="Aktuellstes Jahr"
                >
                    {props.menuItems.map((item) => (
                        <MenuItem value={item} key={item}>
                            {item}
                        </MenuItem>
                    ))}
                    <MenuItem value={"Aktuellstes Jahr"}>{"Aktuellstes Jahr"}</MenuItem>
                </Select>
            </FormControl>
        );
    };

    useEffect(() => {
        setFetchingResultData(true);
        fetchData({ building_filter: buildingFilter, year: yearFilter }).then(() => {
            setFetchingResultData(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildingFilter, yearFilter]);

    return (
        <Box sx={{ maxWidth: "100%", maxHeight: "100%" }}>
            {fetchingResultData && LoadingSpinner}
            <ResultTableProvider>
                <Grid data-testid="data-table-page" container sx={{ maxWidth: "100%" }}>
                    {!fetchingResultData && data.length === 0 ? (
                        <Box
                            width="100%"
                            height="100%"
                            sx={{
                                display: "flex",
                                marginLeft: "5ch",
                                marginTop: "5ch",
                                marginBottom: "5ch",
                                // marginRight: "10%",
                            }}
                            data-testid="no-results-box"
                        >
                            <Alert severity="info" sx={{ textAlign: "left", height: "12ch", fontSize: 14 }}>
                                <strong>Es liegen noch keine Ergebnisse vor.</strong>
                                <br />
                                Fügen Sie unter "Gebäudedetails" ein erstes Gebäude
                                <br />
                                inklusive Verbrauchsdaten und Gebäudezustand
                                <br />
                                hinzu, um Ergebnisse zu sehen.
                                <br />
                            </Alert>
                        </Box>
                    ) : (
                        <Grid item xs={12} sm={displayMap ? 6 : 12}>
                            <Box
                                sx={{
                                    // minWidth: displayMap ? "80ch" : "205ch",
                                    maxWidth: { xs: "100vw", sm: displayMap ? "calc(50vw - 2ch)" : "100vw" },
                                    // mt: 1,
                                }}
                            >
                                <Paper sx={{ width: "100%", mb: 2, mt: 3 }}>
                                    <Grid container>
                                        {/* <Typography
                                            variant="h3"
                                            sx={{ paddingTop: 1.5, paddingLeft: 2, paddingBottom: 2 }}
                                        >
                                            Jahresergebnisse
                                        </Typography> */}
                                        <Dropdown
                                            menuItems={years}
                                            sx={{ mt: 0.5, ml: 1, width: 200 }}
                                            data-testid="years-dropdown"
                                            size="small"
                                        />
                                        <LazyTextField
                                            triggerFunction={setBuildingFilter}
                                            value={buildingFilter}
                                            setValue={setBuildingFilter}
                                            label="Gebäude filtern"
                                            sx={{ mt: 1, mb: 1, ml: 2 }}
                                            data-testid="address-filter-input"
                                            size="small"
                                        />
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            sx={{
                                                alignContent: "right",
                                                ml: "auto",
                                                mr: 1,
                                                mt: 1,
                                                boxShadow: 1,
                                                borderRadius: 1,
                                            }}
                                            onClick={() => setDisplayMap(!displayMap)}
                                        >
                                            <MapOutlinedIcon /> Kartenansicht {displayMap ? "ausblenden" : "einblenden"}
                                        </Fab>
                                    </Grid>

                                    <EnhancedTable
                                        data={data}
                                        sumData={sumData}
                                        dataYear={dataYear}
                                        header={header}
                                        colorColumn="Performance Indikator Raumwärme [kWh/m²]"
                                        colorScale={colorScale}
                                        efficiencyClassColumn="Energie-effizienz-klasse (Verbrauch)"
                                        expectedEfficiencyClassColumn="Erwartete Energie-effizienz-klasse"
                                        relationColumnIdx="8"
                                        sortColumns={[
                                            "ID",
                                            "Erwarteter EE-Verbrauch Raum-wärme [kWh/m²]",
                                            "Performance Indikator Raumwärme [kWh/m²]",
                                            "Endenergie-verbrauch [kWh]",
                                            "Endenergie-verbrauch pro m² [kWh/m²]",
                                            "CO\u2082 Emissionen [kg]",
                                            "CO\u2082 Emissionen pro m² [kg/m²]",
                                            "CO\u2082 Kosten für Vermieter [EUR]",
                                            "CO\u2082 Kosten Einspar-potential Vermieter [EUR]",
                                            "CO\u2082 Einsparung PV [kg]",
                                            // "CO\u2082 Einsparungen PV pro m² [kg/m²]",
                                        ]}
                                        detailColumn="Adresse"
                                        idColumn="int_id"
                                        link="/app/buildingdetails"
                                        data-testid="enhanced-table"
                                    />
                                </Paper>
                            </Box>
                        </Grid>
                    )}
                    {displayMap && (
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ ml: 2 }}>
                                <SimpleMarkerMap
                                    height="calc(100vh - 175px)"
                                    data-testid="result-map"
                                    markers={mapData}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </ResultTableProvider>
        </Box>
    );
};

export default Results;
