/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useContext } from "react";
import { useImmer } from "use-immer";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    TextField,
    Box,
    Slider,
    Tabs,
    Tab,
    Fab,
    Modal,
    CircularProgress,
    Divider,
    Grid,
    InputAdornment,
    Tooltip,
    Alert,
    Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import api_client from "../../services/http-common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConstructionIcon from "@mui/icons-material/Construction";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { ConsumptionScale } from "../../components/ResultBarGauges";
import { addDisplayNumbersInJsonArray, cleanNumbersInJson, createGermanNumberString } from "../../utils/formatting";
import { tooltipClasses } from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ToastContext from "../../contexts/ToastContext";
import UpdateTimeStampContext from "./UpdateTimestampContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { linearInterpolation } from "../../utils/calculation";
import { BoundedIntegerInput, FloatInput, IntegerInput } from "../../components/InputFields";
import EnergyEfficiencyClassesContext from "./EfficiencyClassesContext";
import ReportProblemTwoToneIcon from "@mui/icons-material/ReportProblemTwoTone";
import CloseIcon from "@mui/icons-material/Close";

const NumberInput = styled(TextField)`
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        display: none;
    }
`;

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: "220px",
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
        },
    })
);

const LoadingSpinner = (
    <div
        style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.4)",
            backdropFilter: "blur(3px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
        }}
    >
        <CircularProgress />
    </div>
);

/**
 * Component for displaying and managing building conditions in an accordion format.
 *
 * This component allows users to view, add, edit, and delete building conditions for a specific building.
 * It integrates with various contexts to fetch and display data related to energy efficiency, modernization,
 * and building statistics. It also provides interactive elements like sliders, inputs, and modals for
 * a dynamic user experience.
 *
 * Props:
 *   currentBuilding (Object): The building object for which the conditions are being managed.
 *
 * Returns:
 *   ReactElement: A component that renders an accordion with building condition details and management options.
 */
export const BuildingConditionsAccordion = ({ currentBuilding }) => {
    // -- CONTEXT -- //
    const { addToastMessage } = useContext(ToastContext);
    const { setUpdateTimestamp } = useContext(UpdateTimeStampContext);
    const { energyEfficiencyClasses } = useContext(EnergyEfficiencyClassesContext);
    // -- CONSTANTS -- //
    const theme = useTheme();

    const emptyBuildingCondition = {
        year: 2000,
        note: "",
        living_space: { display: "", real: 0 },
        apartments: { display: "", real: 0 },
        apartments_with_cf_waterheating: { display: "", real: 0 },
        component_modernization_hull: { display: 0, real: 0 },
        component_modernization_windows: { display: 0, real: 0 },
        component_modernization_roof: { display: 0, real: 0 },
        component_modernization_cellarroof: { display: 0, real: 0 },
        component_modernization_ventilation: { display: 0, real: 0 },
        total_degree_of_modernization: { display: 0, real: 0 },
        statistical_consumption_roomheating: 0,
        statistical_consumption_waterheating: 0,
    };

    const modernization_degree_modifier = 1.0 / 0.75;

    const sliderstylezustand = {
        width: "100%",
        // left: "130px",
        top: "-3ch",
    };

    // -- STATES -- //
    // building-condition data
    const [buildingConditionTab, setBuildingConditionTab] = useState(0);
    const [buildingCondition, setBuildingCondition] = useImmer([emptyBuildingCondition]);

    const [fetchingBuildingConditionData, setFetchingBuildingConditionData] = useState(false);

    // handle functions for addYearModal in building-conditions
    const [addConditionYearModalOpen, setAddConditionYearModalOpen] = useState(false);
    const [addConditionYearChoice, setAddConditionYearChoice] = useState();
    const [addConditionYearValid, setAddConditionYearValid] = useState(false);
    const handleOpenAddConditionYear = () => setAddConditionYearModalOpen(true);
    const handleCloseAddConditionYear = () => setAddConditionYearModalOpen(false);

    const [editModeBuildingConditions, setEditModeBuildingConditions] = useState(false);
    const [errorBuildingConditions, setErrorBuildingConditions] = useImmer({});

    // statistical consumption data
    const [statisticalBuildingConsumptions, setStatisticalBuildingConsumptions] = useState();

    // building-condition reference data
    const [ComponentModernizationReferences, setComponentModernizationReferences] = useState();
    const [ComponentModernizationSteps, setComponentModernizationSteps] = useState({
        roof: { steps: [], max_modernization_impact_pct: 0 },
        hull: { steps: [], max_modernization_impact_pct: 0 },
        cellarroof: { steps: [], max_modernization_impact_pct: 0 },
        windows: { steps: [], max_modernization_impact_pct: 0 },
        ventilation: { steps: [], max_modernization_impact_pct: 0 },
    });
    const [modernizationSliderMarks, setModernizationSliderMarks] = useState({
        hull: [{ value: 0, label: "0" }],
        roof: [{ value: 0, label: "0" }],
        cellarroof: [{ value: 0, label: "0" }],
        windows: [{ value: 0, label: "0" }],
        ventilation: [{ value: 0, label: "0" }],
    });

    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const handleOpenDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(true);
    const handleCloseDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(false);

    // -- FUNCTIONS -- //
    // handle the switching of building-conditions year (tab)
    const handleBuildingConditionTab = (event, newValue) => {
        setBuildingConditionTab(newValue);
    };

    const getModernizationReferenceDescription = (
        modernizationImpactPct,
        componentModernizationReferences,
        displayUValue = true
    ) => {
        /*
        Issue #73 https://github.com/lukas-aldenhoff/ARKcalculator/issues/73
        */
        let prevIdx = null;
        let currentIdx = null;
        for (let [idx, elem] of componentModernizationReferences.entries()) {
            currentIdx = idx;
            if (componentModernizationReferences[currentIdx].modernization_impact_pct === modernizationImpactPct) {
                prevIdx = null;
                break;
            }
            if (componentModernizationReferences[currentIdx].modernization_impact_pct > modernizationImpactPct) {
                break;
            }
            prevIdx = currentIdx;
        }
        let description = "";
        if (!displayUValue) {
            description = componentModernizationReferences[currentIdx].description;
            if (prevIdx !== null) {
                description = (
                    <>
                        {"Zwischen:"}
                        <br />
                        {prevIdx.toString() + ": " + componentModernizationReferences[prevIdx].description}
                        <br />
                        {currentIdx.toString() + ": " + description}
                    </>
                );
            }
        } else {
            description =
                "U-Wert von " +
                createGermanNumberString(componentModernizationReferences[currentIdx].u_value) +
                ". Entspricht etwa " +
                componentModernizationReferences[currentIdx].description;

            if (prevIdx !== null) {
                description = (
                    <>
                        {"Zwischen:"}
                        <br />
                        {prevIdx.toString() +
                            ": " +
                            ("U-Wert von " +
                                createGermanNumberString(componentModernizationReferences[prevIdx].u_value) +
                                ". Entspricht etwa " +
                                componentModernizationReferences[prevIdx].description)}
                        <br />
                        {currentIdx.toString() + ": " + description}
                    </>
                );
            }
        }
        return description;
    };

    const setTotalModernization = () => {
        setBuildingCondition((cond) => {
            var deg =
                cond[buildingConditionTab].component_modernization_hull.weighted +
                cond[buildingConditionTab].component_modernization_windows.weighted +
                cond[buildingConditionTab].component_modernization_roof.weighted +
                cond[buildingConditionTab].component_modernization_cellarroof.weighted +
                cond[buildingConditionTab].component_modernization_ventilation.weighted;
            cond[buildingConditionTab].total_degree_of_modernization.real = Number(
                (deg * modernization_degree_modifier).toFixed(0)
            );
            cond[buildingConditionTab].statistical_consumption_roomheating = linearInterpolation(
                deg,
                0,
                75,
                statisticalBuildingConsumptions.gross_energy_roomheating_values
            );
            cond[buildingConditionTab].statistical_consumption_waterheating = linearInterpolation(
                deg,
                0,
                75,
                statisticalBuildingConsumptions.gross_energy_waterheating_values
            );
        });
    };

    // fetch building conditions from backend
    const fetchBuildingConditionData = useCallback(async () => {
        setEditModeBuildingConditions(false);
        setFetchingBuildingConditionData(true);
        api_client.get(`/buildings/${currentBuilding.id}/conditions/`, {}).then((response) => {
            const data = addDisplayNumbersInJsonArray(response.data.conditions);
            const statistical_data = response.data.statistical_consumption_data;

            var adapted_data = data.map((val) => {
                var tmp = {
                    ...val,
                    component_modernization_cellarroof: {
                        display: 0,
                        weighted: 0,
                        real: val.component_modernization_cellarroof.real,
                    },
                    component_modernization_roof: {
                        display: 0,
                        weighted: 0,
                        real: val.component_modernization_roof.real,
                    },
                    component_modernization_hull: {
                        display: 0,
                        weighted: 0,
                        real: val.component_modernization_hull.real,
                    },
                    component_modernization_ventilation: {
                        display: 0,
                        weighted: 0,
                        real: val.component_modernization_ventilation.real,
                    },
                    component_modernization_windows: {
                        display: 0,
                        weighted: 0,
                        real: val.component_modernization_windows.real,
                    },
                    total_degree_of_modernization: {
                        display: 0,
                        real: (val.total_degree_of_modernization.real * modernization_degree_modifier).toFixed(0),
                    },
                };
                tmp.statistical_consumption_roomheating = linearInterpolation(
                    val.total_degree_of_modernization.real,
                    0,
                    75,
                    statistical_data.gross_energy_roomheating_values
                );
                tmp.statistical_consumption_waterheating = linearInterpolation(
                    val.total_degree_of_modernization.real,
                    0,
                    75,
                    statistical_data.gross_energy_waterheating_values
                );
                return tmp;
            });

            // assign local states
            setStatisticalBuildingConsumptions(statistical_data);
            // if we were previously on the last tab, we want to stay on the last tab, else we stay on the current tab (or the last tab if the current tab is not available anymore)
            var tab =
                buildingConditionTab === buildingCondition.length - 1
                    ? data.length - 1
                    : Math.max(0, Math.min(data.length - 1, buildingConditionTab));
            // update the building condition data
            setBuildingCondition((cond) => {
                cond.splice(0, cond.length);
                adapted_data.map((val) => {
                    cond.push(val);
                    return cond;
                });
            });
            // update the total modernization degree display values
            setComponentModernizationDisplayValues();
            setBuildingConditionTab(tab);
            setFetchingBuildingConditionData(false);
        });
    }, [currentBuilding, buildingConditionTab]);

    // fetch building condition reference data
    const fetchBuildingConditionReferenceData = useCallback(async () => {
        setFetchingBuildingConditionData(true);
        api_client.get(`/buildings/${currentBuilding.id}/reference/`, {}).then((response) => {
            const references = response.data.references;
            const modernization_steps = response.data.modernization_steps;

            // assign local state
            setComponentModernizationReferences(references);
            setComponentModernizationSteps(modernization_steps);

            setModernizationSliderMarks({
                hull: references.hull.map((el, idx) => {
                    return {
                        value: modernization_steps.hull.steps.indexOf(
                            el.modernization_impact_pct / (modernization_steps.hull.max_modernization_impact_pct / 100)
                        ),
                        label: idx,
                    };
                }),
                windows: references.windows.map((el, idx) => {
                    return {
                        value: modernization_steps.windows.steps.indexOf(
                            el.modernization_impact_pct /
                                (modernization_steps.windows.max_modernization_impact_pct / 100)
                        ),
                        label: idx,
                    };
                }),
                roof: references.roof.map((el, idx) => {
                    return {
                        value: modernization_steps.roof.steps.indexOf(
                            el.modernization_impact_pct / (modernization_steps.roof.max_modernization_impact_pct / 100)
                        ),
                        label: idx,
                    };
                }),
                cellarroof: references.cellarroof.map((el, idx) => {
                    return {
                        value: modernization_steps.cellarroof.steps.indexOf(
                            el.modernization_impact_pct /
                                (modernization_steps.cellarroof.max_modernization_impact_pct / 100)
                        ),
                        label: idx,
                    };
                }),

                ventilation: references.ventilation.map((el, idx) => {
                    return {
                        value: modernization_steps.ventilation.steps.indexOf(
                            el.modernization_impact_pct /
                                (modernization_steps.ventilation.max_modernization_impact_pct / 100)
                        ),
                        label: idx,
                    };
                }),
            });
        });
        setFetchingBuildingConditionData(false);
    }, [currentBuilding]);

    useEffect(() => {
        fetchBuildingConditionData();
        fetchBuildingConditionReferenceData();
    }, [currentBuilding]);

    const setComponentModernizationDisplayValues = () => {
        if (!ComponentModernizationReferences) {
            return;
        }
        setBuildingCondition((cond) => {
            cond.forEach((condEl) => {
                ComponentModernizationSteps.hull.steps.forEach((cEl, cIdx) => {
                    if (cEl === condEl.component_modernization_hull.real) {
                        condEl.component_modernization_hull.display = cIdx;
                    }
                });
                ComponentModernizationSteps.windows.steps.forEach((cEl, cIdx) => {
                    if (cEl === condEl.component_modernization_windows.real) {
                        condEl.component_modernization_windows.display = cIdx;
                    }
                });
                ComponentModernizationSteps.roof.steps.forEach((cEl, cIdx) => {
                    if (cEl === condEl.component_modernization_roof.real) {
                        condEl.component_modernization_roof.display = cIdx;
                    }
                });
                ComponentModernizationSteps.cellarroof.steps.forEach((cEl, cIdx) => {
                    if (cEl === condEl.component_modernization_cellarroof.real) {
                        condEl.component_modernization_cellarroof.display = cIdx;
                    }
                });
                ComponentModernizationSteps.ventilation.steps.forEach((cEl, cIdx) => {
                    if (cEl === condEl.component_modernization_ventilation.real) {
                        condEl.component_modernization_ventilation.display = cIdx;
                    }
                });
                condEl.component_modernization_hull.weighted =
                    (condEl.component_modernization_hull.real / 100) *
                    ComponentModernizationSteps.hull.max_modernization_impact_pct;
                condEl.component_modernization_windows.weighted =
                    (condEl.component_modernization_windows.real / 100) *
                    ComponentModernizationSteps.windows.max_modernization_impact_pct;
                condEl.component_modernization_roof.weighted =
                    (condEl.component_modernization_roof.real / 100) *
                    ComponentModernizationSteps.roof.max_modernization_impact_pct;
                condEl.component_modernization_cellarroof.weighted =
                    (condEl.component_modernization_cellarroof.real / 100) *
                    ComponentModernizationSteps.cellarroof.max_modernization_impact_pct;
                condEl.component_modernization_ventilation.weighted =
                    (condEl.component_modernization_ventilation.real / 100) *
                    ComponentModernizationSteps.ventilation.max_modernization_impact_pct;
            });
        });
    };

    // useEffect to update component modernization display values when ComponentModernizationReference Data is present
    useEffect(() => {
        setComponentModernizationDisplayValues();
    }, [ComponentModernizationReferences]);

    // delete building condition
    const deleteBuildingCondition = async () => {
        handleCloseDeleteConfirmationModal();
        setFetchingBuildingConditionData(true);
        api_client
            .delete(`/buildings/${currentBuilding.id}/conditions/${buildingCondition[buildingConditionTab].year}/`, {})
            .then((response) => {
                setBuildingConditionTab(Math.max(0, Math.min(buildingCondition.length - 2, buildingConditionTab)));
                if (response.status === 200) {
                    fetchBuildingConditionData();
                    setUpdateTimestamp(Date.now());
                    addToastMessage(
                        "Gebäudezustand erfolgreich gelöscht.",
                        "success",
                        true,
                        6000,
                        "toast-delete-condition-success"
                    );
                } else {
                    addToastMessage(
                        "Beim Löschen des Gebäudezustandes ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                        "error",
                        true,
                        6000,
                        "toast-delete-condition-error"
                    );
                }
                setFetchingBuildingConditionData(false);
            });
    };

    // add years (tabs) to building conditions
    const addBuildingConditionTab = () => {
        handleCloseAddConditionYear();
        const newTabIdx = buildingCondition.length;
        setBuildingCondition((cond) => {
            var lastEl;
            if (buildingCondition.length > 0) {
                lastEl = { ...buildingCondition[buildingCondition.length - 1] };
            } else {
                lastEl = { ...emptyBuildingCondition };
            }
            lastEl.year = parseInt(addConditionYearChoice);

            lastEl.statistical_consumption_roomheating =
                statisticalBuildingConsumptions.gross_energy_roomheating_values[0];
            lastEl.statistical_consumption_waterheating =
                statisticalBuildingConsumptions.gross_energy_waterheating_values[0];
            setComponentModernizationDisplayValues();
            cond.push(lastEl);
        });
        setAddConditionYearChoice("");
        setAddConditionYearValid(false);
        setBuildingConditionTab(newTabIdx);
        setEditModeBuildingConditions(true);
    };

    // handle the toggling of the edit modes
    const handleEditMode = () => {
        if (editModeBuildingConditions === true) {
            fetchBuildingConditionData();
            setEditModeBuildingConditions(false);
        } else {
            setEditModeBuildingConditions(true);
        }
        setErrorBuildingConditions({});
    };

    // handle the saving after editing
    const handleSave = async () => {
        setEditModeBuildingConditions((prevEditMode) => !prevEditMode);
        setFetchingBuildingConditionData(true);
        var _buildingCondition = [cleanNumbersInJson(buildingCondition[buildingConditionTab])];
        // post building-conditions to backend
        api_client.post(`/buildings/${currentBuilding.id}/conditions/`, _buildingCondition).then((response) => {
            const building_condition_status = response.status;
            if (building_condition_status === 200) {
                const data = addDisplayNumbersInJsonArray(response.data.conditions);
                setBuildingCondition((cond) => {
                    cond[buildingConditionTab] = data[0];
                    cond[buildingConditionTab].statistical_consumption_roomheating = linearInterpolation(
                        data[0].total_degree_of_modernization.real,
                        0,
                        75,
                        statisticalBuildingConsumptions.gross_energy_roomheating_values
                    );
                    cond[buildingConditionTab].statistical_consumption_waterheating = linearInterpolation(
                        data[0].total_degree_of_modernization.real,
                        0,
                        75,
                        statisticalBuildingConsumptions.gross_energy_waterheating_values
                    );
                    cond[buildingConditionTab].total_degree_of_modernization.real = (
                        data[0].total_degree_of_modernization.real * modernization_degree_modifier
                    ).toFixed(0);
                });
                setComponentModernizationDisplayValues();
            }
            setUpdateTimestamp(Date.now());
            setFetchingBuildingConditionData(false);
        });
    };

    return (
        <>
            <DeleteConfirmationModal
                deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
                confirmationText={
                    "Sind Sie sicher, dass Sie " +
                    'den Gebäudezustand "' +
                    buildingCondition[buildingConditionTab]?.year +
                    '"' +
                    " und alle zugehörigen Daten löschen möchten?"
                }
                deletionTargetFunction={deleteBuildingCondition}
            />
            <Box sx={{ mt: 3 }}>
                <Accordion sx={{ width: "160ch" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ConstructionIcon sx={{ fontSize: 30 }} />{" "}
                        <Typography
                            sx={{
                                ml: 2,
                                fontSize: 20,
                                fontWeight: "bold",
                            }}
                        >
                            Gebäudezustand
                        </Typography>
                        {buildingCondition.length === 0 && (
                            <CustomTooltip title="Zu diesem Gebäude sind noch keine Zustandsdaten vorhanden.">
                                <ReportProblemTwoToneIcon sx={{ color: "#ff9e17", fontSize: 23, mt: 0.6, ml: 2 }} />
                            </CustomTooltip>
                        )}
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <Divider sx={{ mb: 2, mt: -2, width: "100%" }} />
                        {fetchingBuildingConditionData && LoadingSpinner}
                        <Box sx={{ ml: 0 }} width="90%">
                            <Fab
                                variant="extended"
                                size="medium"
                                onClick={handleOpenAddConditionYear}
                                disabled={editModeBuildingConditions}
                                sx={{ zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                            >
                                <AddIcon sx={{ mr: 1 }} />
                                Gebäudezustand hinzufügen
                            </Fab>
                            {buildingCondition.length > 0 && (
                                <Fab
                                    variant="extended"
                                    size="medium"
                                    disabled={editModeBuildingConditions}
                                    onClick={(e) => {
                                        handleOpenDeleteConfirmationModal();
                                    }}
                                    sx={{ ml: 2, zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                                >
                                    <DeleteIcon />
                                    Gebäudezustand löschen
                                </Fab>
                            )}
                            <Modal
                                open={addConditionYearModalOpen}
                                onClose={handleCloseAddConditionYear}
                                aria-labelledby="add-year-modal"
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        if (addConditionYearValid) {
                                            addBuildingConditionTab();
                                        }
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: 400,
                                        bgcolor: "background.paper",
                                        border: "2px solid #000",
                                        boxShadow: 24,
                                        p: 4,
                                    }}
                                >
                                    <Button
                                        sx={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "5px",
                                        }}
                                        onClick={() => handleCloseAddConditionYear()}
                                    >
                                        <CloseIcon />
                                    </Button>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Für welches Jahr möchten Sie einen Gebäudezustand hinzufügen?
                                    </Typography>
                                    <NumberInput
                                        value={addConditionYearChoice}
                                        variant="outlined"
                                        error={!addConditionYearValid}
                                        onChange={(e) => {
                                            if (
                                                parseInt(e.target.value) < 1800 ||
                                                parseInt(e.target.value) > 2050 ||
                                                e.target.value.length !== 4 ||
                                                !buildingCondition.every((c) => c.year !== parseInt(e.target.value))
                                            ) {
                                                setAddConditionYearValid(false);
                                            } else {
                                                setAddConditionYearValid(true);
                                            }
                                            setAddConditionYearChoice(parseInt(e.target.value));
                                        }}
                                        sx={{ mt: 1 }}
                                        size="small"
                                        label="Jahr"
                                        type="number"
                                    />
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        disabled={!addConditionYearValid}
                                        onClick={(e) => addBuildingConditionTab()}
                                        color="secondary"
                                        sx={{ ml: 3, mt: 0.8, boxShadow: 1, borderRadius: 1 }}
                                    >
                                        Speichern
                                    </Fab>
                                </Box>
                            </Modal>
                            {buildingCondition.length > 0 ? (
                                <>
                                    <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                        width="100%"
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter" && editModeBuildingConditions) {
                                                if (
                                                    !(
                                                        Object.keys(errorBuildingConditions).length > 0 &&
                                                        !Object.values(errorBuildingConditions).every(
                                                            (item) => item === false
                                                        )
                                                    )
                                                ) {
                                                    handleSave();
                                                }
                                            }
                                        }}
                                    >
                                        <div
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.5rem",
                                                mt: 2,
                                                border: 1,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Box sx={{ bgcolor: theme.palette.secondary.main }} width="100ch">
                                                <Tabs
                                                    TabIndicatorProps={{
                                                        children: <span className="MuiTabs-indicatorSpan" />,
                                                    }}
                                                    value={buildingConditionTab}
                                                    onChange={handleBuildingConditionTab}
                                                    variant="scrollable"
                                                    scrollButtons={true}
                                                    sx={{
                                                        borderColor: theme.palette.neutral.light,
                                                        height: "20px",
                                                        // bgcolor: theme.palette.primary.main,
                                                        mb: 1,
                                                        mt: 2,
                                                        "& .MuiTabs-scrollButtons.Mui-disabled": {
                                                            opacity: 0.3,
                                                        },
                                                        "& .Mui-selected": {
                                                            color: "primary",
                                                        },
                                                        "& .MuiTabs-indicator": {
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            backgroundColor: "transparent",
                                                            height: 4,
                                                            mb: "7px",
                                                        },
                                                        "& .MuiTabs-indicatorSpan": {
                                                            maxWidth: 50,
                                                            width: "100%",
                                                            backgroundColor: theme.palette.background.default,
                                                            height: "100%",
                                                        },
                                                    }}
                                                >
                                                    {buildingCondition.map((val, index) => {
                                                        return (
                                                            <Tab
                                                                disabled={editModeBuildingConditions}
                                                                label={val.year}
                                                                id={index}
                                                                sx={{ fontWeight: "bold", fontSize: "20px" }}
                                                            />
                                                        );
                                                    })}
                                                </Tabs>
                                            </Box>
                                            <div
                                                sx={{
                                                    marginLeft: "auto",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <Fab
                                                    variant="extended"
                                                    size="medium"
                                                    color={editModeBuildingConditions ? "default" : "secondary"}
                                                    onClick={() => {
                                                        handleEditMode();
                                                    }}
                                                    sx={{
                                                        marginRight: "1rem",
                                                        zIndex: 0,
                                                        boxShadow: 1,
                                                        borderRadius: 1,
                                                    }}
                                                >
                                                    {editModeBuildingConditions ? <></> : <EditIcon sx={{ mr: 1 }} />}
                                                    {editModeBuildingConditions ? "Abbrechen" : "Bearbeiten"}
                                                </Fab>
                                                {editModeBuildingConditions && (
                                                    <Fab
                                                        disabled={
                                                            Object.keys(errorBuildingConditions).length > 0 &&
                                                            !Object.values(errorBuildingConditions).every(
                                                                (item) => item === false
                                                            )
                                                        }
                                                        variant="extended"
                                                        size="medium"
                                                        color={editModeBuildingConditions ? "secondary" : "default"}
                                                        onClick={() => handleSave()}
                                                        sx={{
                                                            marginRight: "1rem",
                                                            zIndex: 0,
                                                            boxShadow: 1,
                                                            borderRadius: 1,
                                                        }}
                                                    >
                                                        <SaveIcon sx={{ mr: 1 }} />
                                                        Speichern
                                                    </Fab>
                                                )}
                                            </div>

                                            <div>
                                                <TextField
                                                    variant={editModeBuildingConditions ? "outlined" : "filled"}
                                                    label="Anmerkung zum Gebäudezustand"
                                                    value={buildingCondition[buildingConditionTab].note}
                                                    onChange={(e) =>
                                                        setBuildingCondition((cond) => {
                                                            cond[[buildingConditionTab]].note = e.target.value;
                                                        })
                                                    }
                                                    disabled={!editModeBuildingConditions}
                                                    multiline
                                                    rows={2}
                                                    sx={{ mt: 1, mr: 1, ml: 0, width: "44ch" }}
                                                />
                                            </div>

                                            <div>
                                                <FloatInput
                                                    variant={editModeBuildingConditions ? "outlined" : "filled"}
                                                    label="Wohnfläche gesamt"
                                                    size="small"
                                                    value={buildingCondition[buildingConditionTab].living_space}
                                                    onChange={(val) =>
                                                        setBuildingCondition((cond) => {
                                                            cond[[buildingConditionTab]].living_space = val;
                                                        })
                                                    }
                                                    disabled={!editModeBuildingConditions}
                                                    sx={{ mt: 1, mr: 1, ml: 0, width: "23ch" }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                sx={{ mt: editModeBuildingConditions ? 0 : 0 }}
                                                            >
                                                                {"m\u00B2"}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    validate={(val) => {
                                                        return true;
                                                    }}
                                                    error={errorBuildingConditions.living_space}
                                                    setError={(val) => {
                                                        setErrorBuildingConditions((err) => {
                                                            err.living_space = val;
                                                        });
                                                    }}
                                                />
                                                <IntegerInput
                                                    thousandsSeparator={true}
                                                    variant={editModeBuildingConditions ? "outlined" : "filled"}
                                                    label="Anzahl Wohnungen"
                                                    size="small"
                                                    value={buildingCondition[buildingConditionTab].apartments}
                                                    onChange={(val) =>
                                                        setBuildingCondition((cond) => {
                                                            cond[[buildingConditionTab]].apartments = val;
                                                        })
                                                    }
                                                    disabled={!editModeBuildingConditions}
                                                    sx={{
                                                        mt: 1,
                                                        width: "20ch",
                                                    }}
                                                    validate={(val) => {
                                                        if (!val) {
                                                            return false;
                                                        }
                                                        return true;
                                                    }}
                                                    error={errorBuildingConditions.apartments}
                                                    setError={(val) => {
                                                        setErrorBuildingConditions((err) => {
                                                            err.apartments = val;
                                                        });
                                                    }}
                                                />
                                                <div>
                                                    <BoundedIntegerInput
                                                        thousandsSeparator={true}
                                                        variant={editModeBuildingConditions ? "outlined" : "filled"}
                                                        label="Anzahl Whg. mit dez. Warmwasseraufbereitung"
                                                        size="small"
                                                        value={
                                                            buildingCondition[buildingConditionTab]
                                                                .apartments_with_cf_waterheating
                                                        }
                                                        onChange={(val) => {
                                                            setBuildingCondition((cond) => {
                                                                cond[
                                                                    [buildingConditionTab]
                                                                ].apartments_with_cf_waterheating = val;
                                                            });
                                                        }}
                                                        disabled={!editModeBuildingConditions}
                                                        sx={{
                                                            mt: 1,
                                                            mr: 1,
                                                            width: "44ch",
                                                        }}
                                                        validate={(val) => {
                                                            if (
                                                                parseInt(val) >
                                                                buildingCondition[buildingConditionTab].apartments.real
                                                            ) {
                                                                return false;
                                                            } else {
                                                                return true;
                                                            }
                                                        }}
                                                        error={errorBuildingConditions.apartments_with_cf_waterheating}
                                                        setError={(val) => {
                                                            setErrorBuildingConditions((err) => {
                                                                err.apartments_with_cf_waterheating = val;
                                                            });
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="end"
                                                                    sx={{
                                                                        mt: editModeBuildingConditions ? 0 : 0,
                                                                    }}
                                                                >
                                                                    <CustomTooltip title="Anzahl an Wohnungen, die primär eine dezentrale Warmwasseraufbereitung, wie einen Durchlauferhitzer, nutzen.">
                                                                        <InfoIcon color="neutral" />
                                                                    </CustomTooltip>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <Divider sx={{ mt: 2, width: 600 }}></Divider>
                                                <Grid container>
                                                    <Box width="100%">
                                                        <Typography
                                                            sx={{
                                                                ml: 0,
                                                                mt: 2,
                                                                mb: 4,
                                                                fontSize: 20,
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            Modernisierungszustand
                                                            <CustomTooltip title='Der Modernisierungszustand ist auf das Baujahr des Gebäudes normiert. Die Angabe "0" für den Modernisierungszustand eines Bauteils entspricht also etwa den gängigen Komponenten, die im angegebenen Baujahr für diesen Gebäudetyp verwendet wurden.'>
                                                                <InfoIcon
                                                                    sx={{ mb: "-3px", ml: "10px" }}
                                                                    color="neutral"
                                                                />
                                                            </CustomTooltip>
                                                        </Typography>
                                                        <Grid
                                                            container
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Box
                                                                width="10%"
                                                                sx={{
                                                                    ml: 0,
                                                                    mt: "-5px",
                                                                    fontSize: 16,
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Fassade
                                                            </Box>
                                                            <Box width="30%" height="7ch">
                                                                <Slider
                                                                    style={sliderstylezustand}
                                                                    color="secondary"
                                                                    aria-label="Prozent"
                                                                    defaultValue={0}
                                                                    valueLabelDisplay="off"
                                                                    step={1}
                                                                    marks={modernizationSliderMarks.hull}
                                                                    min={0}
                                                                    max={
                                                                        ComponentModernizationSteps.hull.steps.length -
                                                                        1
                                                                    }
                                                                    disabled={!editModeBuildingConditions}
                                                                    value={
                                                                        buildingCondition[buildingConditionTab]
                                                                            .component_modernization_hull.display
                                                                    }
                                                                    onChange={(e) => {
                                                                        setBuildingCondition((cond) => {
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_hull.real =
                                                                                ComponentModernizationSteps.hull.steps[
                                                                                    e.target.value
                                                                                ];
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_hull.display =
                                                                                e.target.value;
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_hull.weighted =
                                                                                (ComponentModernizationSteps.hull.steps[
                                                                                    e.target.value
                                                                                ] *
                                                                                    ComponentModernizationSteps.hull
                                                                                        .max_modernization_impact_pct) /
                                                                                100;
                                                                        });
                                                                        setTotalModernization();
                                                                    }}
                                                                    sx={{
                                                                        mt: "42px",
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box width="2%" height="25px" ml="13px">
                                                                <InfoIcon color="neutral" />
                                                            </Box>
                                                            <Box width="50%">
                                                                <Typography sx={{ fontSize: 12 }}>
                                                                    {ComponentModernizationReferences
                                                                        ? getModernizationReferenceDescription(
                                                                              buildingCondition[buildingConditionTab]
                                                                                  .component_modernization_hull
                                                                                  .weighted,
                                                                              ComponentModernizationReferences.hull
                                                                          )
                                                                        : ""}
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                width="10%"
                                                                sx={{
                                                                    ml: 0,
                                                                    mt: "-5px",
                                                                    fontSize: 16,
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Fenster
                                                            </Box>
                                                            <Box width="30%" height="7ch">
                                                                <Slider
                                                                    style={sliderstylezustand}
                                                                    color="secondary"
                                                                    aria-label="Prozent"
                                                                    defaultValue={0}
                                                                    valueLabelDisplay="off"
                                                                    step={1}
                                                                    marks={modernizationSliderMarks.windows}
                                                                    min={0}
                                                                    max={
                                                                        ComponentModernizationSteps.windows.steps
                                                                            .length - 1
                                                                    }
                                                                    disabled={!editModeBuildingConditions}
                                                                    value={
                                                                        buildingCondition[buildingConditionTab]
                                                                            .component_modernization_windows.display
                                                                    }
                                                                    onChange={(e) => {
                                                                        setBuildingCondition((cond) => {
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_windows.real =
                                                                                ComponentModernizationSteps.windows.steps[
                                                                                    e.target.value
                                                                                ];
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_windows.display =
                                                                                e.target.value;
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_windows.weighted =
                                                                                (ComponentModernizationSteps.windows
                                                                                    .steps[e.target.value] *
                                                                                    ComponentModernizationSteps.windows
                                                                                        .max_modernization_impact_pct) /
                                                                                100;
                                                                        });

                                                                        setTotalModernization();
                                                                    }}
                                                                    sx={{
                                                                        mt: "42px",
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box width="2%" height="25px" ml="13px">
                                                                <InfoIcon color="neutral" />
                                                            </Box>
                                                            <Box width="50%">
                                                                <Typography sx={{ fontSize: 12 }}>
                                                                    {ComponentModernizationReferences
                                                                        ? getModernizationReferenceDescription(
                                                                              buildingCondition[buildingConditionTab]
                                                                                  .component_modernization_windows
                                                                                  .weighted,
                                                                              ComponentModernizationReferences.windows
                                                                          )
                                                                        : ""}
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                width="10%"
                                                                sx={{
                                                                    ml: 0,
                                                                    mt: "-5px",
                                                                    fontSize: 16,
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Dach
                                                            </Box>
                                                            <Box width="30%" height="7ch">
                                                                <Slider
                                                                    style={sliderstylezustand}
                                                                    color="secondary"
                                                                    aria-label="Prozent"
                                                                    defaultValue={0}
                                                                    valueLabelDisplay="off"
                                                                    step={1}
                                                                    marks={modernizationSliderMarks.roof}
                                                                    min={0}
                                                                    max={
                                                                        ComponentModernizationSteps.roof.steps.length -
                                                                        1
                                                                    }
                                                                    disabled={!editModeBuildingConditions}
                                                                    value={
                                                                        buildingCondition[buildingConditionTab]
                                                                            .component_modernization_roof.display
                                                                    }
                                                                    onChange={(e) => {
                                                                        setBuildingCondition((cond) => {
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_roof.real =
                                                                                ComponentModernizationSteps.roof.steps[
                                                                                    e.target.value
                                                                                ];
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_roof.display =
                                                                                e.target.value;
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_roof.weighted =
                                                                                (ComponentModernizationSteps.roof.steps[
                                                                                    e.target.value
                                                                                ] *
                                                                                    ComponentModernizationSteps.roof
                                                                                        .max_modernization_impact_pct) /
                                                                                100;
                                                                        });
                                                                        setTotalModernization();
                                                                    }}
                                                                    sx={{
                                                                        mt: "42px",
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box width="2%" height="25px" ml="13px">
                                                                <InfoIcon color="neutral" />
                                                            </Box>
                                                            <Box width="50%">
                                                                <Typography sx={{ fontSize: 12 }}>
                                                                    {ComponentModernizationReferences
                                                                        ? getModernizationReferenceDescription(
                                                                              buildingCondition[buildingConditionTab]
                                                                                  .component_modernization_roof
                                                                                  .weighted,
                                                                              ComponentModernizationReferences.roof
                                                                          )
                                                                        : ""}
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                width="10%"
                                                                sx={{
                                                                    ml: 0,
                                                                    mt: "-5px",
                                                                    fontSize: 16,
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Keller(-decke)
                                                            </Box>
                                                            <Box width="30%" height="7ch">
                                                                <Slider
                                                                    style={sliderstylezustand}
                                                                    color="secondary"
                                                                    aria-label="Prozent"
                                                                    defaultValue={0}
                                                                    valueLabelDisplay="off"
                                                                    step={1}
                                                                    marks={modernizationSliderMarks.cellarroof}
                                                                    min={0}
                                                                    max={
                                                                        ComponentModernizationSteps.cellarroof.steps
                                                                            .length - 1
                                                                    }
                                                                    disabled={!editModeBuildingConditions}
                                                                    value={
                                                                        buildingCondition[buildingConditionTab]
                                                                            .component_modernization_cellarroof.display
                                                                    }
                                                                    onChange={(e) => {
                                                                        setBuildingCondition((cond) => {
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_cellarroof.real =
                                                                                ComponentModernizationSteps.cellarroof.steps[
                                                                                    e.target.value
                                                                                ];
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_cellarroof.display =
                                                                                e.target.value;
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_cellarroof.weighted =
                                                                                (ComponentModernizationSteps.cellarroof
                                                                                    .steps[e.target.value] *
                                                                                    ComponentModernizationSteps
                                                                                        .cellarroof
                                                                                        .max_modernization_impact_pct) /
                                                                                100;
                                                                        });
                                                                        setTotalModernization();
                                                                    }}
                                                                    sx={{
                                                                        mt: "42px",
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box width="2%" height="25px" ml="13px">
                                                                <InfoIcon color="neutral" />
                                                            </Box>
                                                            <Box width="50%">
                                                                <Typography sx={{ fontSize: 12 }}>
                                                                    {ComponentModernizationReferences
                                                                        ? getModernizationReferenceDescription(
                                                                              buildingCondition[buildingConditionTab]
                                                                                  .component_modernization_cellarroof
                                                                                  .weighted,
                                                                              ComponentModernizationReferences.cellarroof
                                                                          )
                                                                        : ""}
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                width="10%"
                                                                sx={{
                                                                    ml: 0,
                                                                    mt: "-5px",
                                                                    fontSize: 16,
                                                                    fontWeight: "bold",
                                                                    alignContent: "center",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                }}
                                                            >
                                                                Lüftung
                                                            </Box>
                                                            <Box width="30%" height="7ch">
                                                                <Slider
                                                                    style={sliderstylezustand}
                                                                    color="secondary"
                                                                    aria-label="Prozent"
                                                                    defaultValue={0}
                                                                    valueLabelDisplay="off"
                                                                    step={1}
                                                                    marks={modernizationSliderMarks.ventilation}
                                                                    min={0}
                                                                    max={
                                                                        ComponentModernizationSteps.ventilation.steps
                                                                            .length - 1
                                                                    }
                                                                    disabled={!editModeBuildingConditions}
                                                                    value={
                                                                        buildingCondition[buildingConditionTab]
                                                                            .component_modernization_ventilation.display
                                                                    }
                                                                    onChange={(e) => {
                                                                        setBuildingCondition((cond) => {
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_ventilation.real =
                                                                                ComponentModernizationSteps.ventilation.steps[
                                                                                    e.target.value
                                                                                ];
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_ventilation.display =
                                                                                e.target.value;
                                                                            cond[
                                                                                [buildingConditionTab]
                                                                            ].component_modernization_ventilation.weighted =
                                                                                (ComponentModernizationSteps.ventilation
                                                                                    .steps[e.target.value] *
                                                                                    ComponentModernizationSteps
                                                                                        .ventilation
                                                                                        .max_modernization_impact_pct) /
                                                                                100;
                                                                        });
                                                                        setTotalModernization();
                                                                    }}
                                                                    sx={{
                                                                        mt: "42px",
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box width="2%" height="25px" ml="13px">
                                                                <InfoIcon color="neutral" />
                                                            </Box>
                                                            <Box width="50%">
                                                                <Typography sx={{ fontSize: 12 }}>
                                                                    {ComponentModernizationReferences
                                                                        ? getModernizationReferenceDescription(
                                                                              buildingCondition[buildingConditionTab]
                                                                                  .component_modernization_ventilation
                                                                                  .weighted,
                                                                              ComponentModernizationReferences.ventilation,
                                                                              false
                                                                          )
                                                                        : ""}
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                width="10%"
                                                                sx={{
                                                                    ml: 0,
                                                                    mt: 1.5,
                                                                    mb: 3,
                                                                    fontSize: 16,
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                Gesamt
                                                            </Box>
                                                            <Box width="30%" height="7ch">
                                                                <Slider
                                                                    style={sliderstylezustand}
                                                                    aria-label="Prozent"
                                                                    defaultValue={0}
                                                                    valueLabelDisplay="on"
                                                                    step={1}
                                                                    min={0}
                                                                    max={100}
                                                                    InputProps={{ readOnly: true }}
                                                                    value={
                                                                        buildingCondition[buildingConditionTab]
                                                                            .total_degree_of_modernization.real
                                                                    }
                                                                    sx={{
                                                                        mt: 4.3,
                                                                        mb: 3,
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box width="60%">
                                                                <Typography ml="13px">
                                                                    <Grid container>
                                                                        <Box width="10%" height="40px">
                                                                            {/* <InfoIcon color="neutral" /> */}
                                                                        </Box>
                                                                        <Box width="85%"></Box>
                                                                    </Grid>
                                                                </Typography>
                                                            </Box>
                                                            {buildingCondition[buildingConditionTab]
                                                                .statistical_consumption_roomheating > 0 &&
                                                            energyEfficiencyClasses ? (
                                                                <Grid container>
                                                                    <Box width="41%">
                                                                        <ConsumptionScale
                                                                            consumptionMarker={
                                                                                buildingCondition[buildingConditionTab]
                                                                                    .statistical_consumption_roomheating +
                                                                                buildingCondition[buildingConditionTab]
                                                                                    .statistical_consumption_waterheating
                                                                            }
                                                                            energyEfficiencyClasses={
                                                                                energyEfficiencyClasses
                                                                            }
                                                                            heightScales={0.9}
                                                                        />
                                                                    </Box>

                                                                    <Box width="2%" ml="13px" mt="10px">
                                                                        <InfoIcon color="neutral" />
                                                                    </Box>
                                                                    <Box width="40%">
                                                                        <Typography mt="10px">
                                                                            Raumwärme + Warmwasser, bezogen auf den
                                                                            Energieverbrauch ab Wärmeerzeuger
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minHeight: "200px",
                                        width: "100%",
                                    }}
                                >
                                    <Alert severity="info" sx={{ textAlign: "left", height: "8ch", fontSize: 14 }}>
                                        <strong>Es sind keine Gebäudezustände verfügbar.</strong>
                                        <br />
                                        Bitte fügen Sie einen Gebäudezustand über "Gebäudezustand hinzufügen" hinzu.
                                        <br />
                                    </Alert>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};
