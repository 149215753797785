import React, { useState } from "react";
import { TextField } from "@mui/material";

/**
 * Renders a text field with lazy update behavior.
 *
 * This component is a wrapper around the Material-UI TextField component. It updates its parent's state
 * only when the user presses the Enter key or the field loses focus (onBlur event). This behavior is useful
 * for reducing the number of updates triggered by continuous typing, especially when the updates involve
 * expensive operations like API calls or complex state changes.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.triggerFunction - The function to call with the new value on Enter press or onBlur event.
 * @param {Object} props.sx - The style object applied to the TextField component.
 * @param {string} props.label - The label text for the TextField component.
 * @returns {JSX.Element} A TextField component with lazy update behavior.
 *
 * @example
 * <LazyTextField
 *   triggerFunction={handleUpdate}
 *   label="Search"
 *   sx={{ width: '100%' }}
 * />
 */
const LazyTextField = (props) => {
    const [inputValue, setInputValue] = useState("");

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            props.triggerFunction(event.target.value);
        }
    };

    const handleBlur = (event) => {
        props.triggerFunction(event.target.value);
    };

    return (
        <TextField
            {...props}
            sx={props.sx}
            label={props.label}
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
        />
    );
};

export default LazyTextField;
