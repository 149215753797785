import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api_client from "../services/http-common";

const AuthContext = createContext();

export default AuthContext;

/**
 * Provides an authentication context for the application.
 *
 * This component manages the authentication state and provides functions for logging in and logging out.
 * It checks for token expiry to maintain the session and uses local storage to persist authentication data.
 *
 * Props:
 *   children (ReactNode): The child components that will consume the authentication context.
 *
 * Returns:
 *   ReactNode: A context provider wrapping its children, passing down authentication state and control functions.
 */
export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    useEffect(() => {
        const tokenexpiry = localStorage.getItem("tokenexpiry");
        if (tokenexpiry > Date.now()) {
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
        }
    }, []);

    const navigate = useNavigate();

    let _csrfToken = null;

    async function getCsrfToken() {
        if (_csrfToken === null) {
            const response = await api_client.get(`/auth/csrf`, {});
            const data = await response.data;
            _csrfToken = data.csrftoken;
        }
        return _csrfToken;
    }

    const loginUser = async (username, password, setErrorMessage, setErrorTextfields) => {
        await getCsrfToken();
        try {
            const response = await api_client.post(`/auth/login`, {
                username: username,
                password: password,
            });
            const status = await response.status;
            if (status === 200) {
                const data = await response.data;
                if (data.success === true) {
                    localStorage.setItem("tokenexpiry", Date.now() + 360000);
                    localStorage.setItem("username", data.user);
                    localStorage.setItem("usergroup", data.usergroup);
                    setAuthenticated(true);
                    navigate("/app/dashboard");
                    return true;
                }
            }
        } catch (error) {
            if (error.response.status === 400) {
                setErrorMessage("Angegebener Benutzername oder Passwort ist inkorrekt. Bitte versuchen sie es erneut.");
                setErrorTextfields(true);
                return false;
            } else {
                return false;
            }
        }
    };

    const logoutUser = async () => {
        localStorage.removeItem("tokenexpiry");
        localStorage.removeItem("username");
        localStorage.removeItem("usergroup");
        const response = await api_client.post(`/auth/logout`, {});
        const status = await response.status;
        if (status === 200) {
            setAuthenticated(false);
            navigate("/");
            return true;
        }
        return false;
    };

    const contextData = {
        authenticated,
        loginUser,
        logoutUser,
    };

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};
