/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import {
    Typography,
    TextField,
    Box,
    Fab,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import { FloatInput, IntegerInput, ValidatedTextField } from "../../../components/InputFields";
import {
    CustomAccordion,
    CustomAccordionDetails,
    CustomAccordionSummary,
    CustomAccordionActions,
} from "../../../components/CustomAccordion";
import { CustomTooltip } from "../../../components/CustomTooltip";
import { createGermanNumberString } from "../../../utils/formatting";

export const NewHeatingSystemDetail = ({
    editModeBuildingHeatingSystem,
    setEditModeBuildingHeatingSystem,
    energySources,
    emissionModels,
    heatingSystemAccordionExpanded,
    handleHeatingSystemAccordionExpanded,
    buildingHeatingSystemsState,
    buildingHeatingSystemsStateTab,
    handleSave,
    estimatedSharesOfConsumptionRoomheating,
    estimatedSharesOfConsumptionWaterheating,
    // consumptionTypes,
    heatingSystemTypes,
}) => {
    const [emptyHeatingSystem, setEmptyHeatingSystem] = useState({
        id: null,
        energy_source: "-",
        heating_system_type: "",
        year: "",
        consumption_type: "RWWW",
        degree_of_efficiency: { display: "0,9", real: 0.9 },
        individual_degree_of_efficiency: { display: "", real: null },
        estimated_share_of_consumption_roomheating: {
            display: "100",
            real: 100,
        },
        estimated_share_of_consumption_waterheating: {
            display: "100",
            real: 100,
        },
        emission_model: "",
        note: "",
    });

    useEffect(() => {
        setEmptyHeatingSystem({
            ...emptyHeatingSystem,
            estimated_share_of_consumption_roomheating: {
                display:
                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems.length > 0
                        ? "0"
                        : "100",
                real:
                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems.length > 0 ? 0 : 100,
            },
            estimated_share_of_consumption_waterheating: {
                display:
                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems.length > 0
                        ? "0"
                        : "100",
                real:
                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems.length > 0 ? 0 : 100,
            },
        });
        setNewHeatingSystem((cond) => {
            cond.estimated_share_of_consumption_roomheating = {
                display:
                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems.length > 0
                        ? "0"
                        : "100",
                real:
                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems.length > 0 ? 0 : 100,
            };
            cond.estimated_share_of_consumption_waterheating = {
                display:
                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems.length > 0
                        ? "0"
                        : "100",
                real:
                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems.length > 0 ? 0 : 100,
            };
        });
        return () => {};
    }, [buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.heating_systems.length]);

    const [newHeatingSystem, setNewHeatingSystem] = useImmer(emptyHeatingSystem);
    const [errorNewHeatingSystem, setErrorNewHeatingSystem] = useImmer({});

    const handleEditMode = () => {
        if (editModeBuildingHeatingSystem === true) {
            setNewHeatingSystem(emptyHeatingSystem);
        }
        setEditModeBuildingHeatingSystem((prevEditMode) => !prevEditMode);
        setErrorNewHeatingSystem({});
    };

    const getHeatingSystemTypeFromId = (id) => {
        const heatingSystemType = heatingSystemTypes.find((item) => {
            return item.id === id;
        });
        if (heatingSystemType) {
            return heatingSystemType;
        } else {
            return null;
        }
    };

    const heatingSystemType = getHeatingSystemTypeFromId(newHeatingSystem.heating_system_type);
    const energySource = heatingSystemType?.energy_source ?? "";

    return (
        <CustomAccordion
            sx={{ maxWidth: "67ch" }}
            expanded={heatingSystemAccordionExpanded === -1}
            onChange={handleHeatingSystemAccordionExpanded(-1)}
            disabled={!(heatingSystemAccordionExpanded === -1) && editModeBuildingHeatingSystem}
        >
            <CustomAccordionSummary>
                <Box width="93%">
                    <Typography
                        sx={{
                            ml: 2,
                            mt: 1,
                            fontSize: 15,
                        }}
                    >
                        <b>
                            {buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.heating_systems?.length > 0 ??
                                "Weitere "}
                            Wärmeversorgungsanlage hinzufügen
                        </b>
                    </Typography>
                </Box>
            </CustomAccordionSummary>
            <CustomAccordionDetails sx={{ height: "34ch" }}>
                <div>
                    {/* ------------------------- Energieträger der Heizungsanlage ------------------------- */}
                    <FormControl sx={{ mt: 1, mr: 1 }}>
                        <InputLabel
                            disabled={!editModeBuildingHeatingSystem}
                            sx={{
                                mt:
                                    !editModeBuildingHeatingSystem && (energySource || newHeatingSystem?.energy_source)
                                        ? 1.5
                                        : energySource || newHeatingSystem?.energy_source
                                        ? 0
                                        : -0.8,
                            }}
                        >
                            Energieträger
                        </InputLabel>
                        <Select
                            variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                            label="Energieträger"
                            size="small"
                            defaultValue={energySource}
                            value={newHeatingSystem.energy_source}
                            onChange={(e) =>
                                setNewHeatingSystem((cond) => {
                                    cond.energy_source = e.target.value;
                                    cond.degree_of_efficiency = {
                                        display: createGermanNumberString(
                                            energySources.find((item) => item.name === e.target.value)
                                                ?.degree_of_efficiency
                                        ),
                                        real: energySources.find((item) => item.name === e.target.value)
                                            ?.degree_of_efficiency,
                                    };
                                    cond.emission_model =
                                        emissionModels.find(
                                            (item) => item.energy_source === e.target.value && item.is_default
                                        )?.id ?? "";
                                    cond.heating_system_type = "";
                                })
                            }
                            disabled={!editModeBuildingHeatingSystem}
                            sx={{
                                width: "27ch",
                            }}
                            default={"-"}
                        >
                            {energySources.map((item) => (
                                <MenuItem key={item.name} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                            <MenuItem key="-" value={"-"}>
                                ---
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {/* ------------------------- Heizungsanlagentyp ------------------------- */}
                    <FormControl sx={{ mt: 1, mr: 1 }}>
                        <InputLabel
                            disabled={!editModeBuildingHeatingSystem}
                            sx={{
                                mt:
                                    !editModeBuildingHeatingSystem && newHeatingSystem.heating_system_type
                                        ? 1.5
                                        : editModeBuildingHeatingSystem
                                        ? -0.8
                                        : 0,
                            }}
                        >
                            Anlagentyp
                        </InputLabel>
                        <Select
                            variant={
                                editModeBuildingHeatingSystem && newHeatingSystem.energy_source !== "-"
                                    ? "outlined"
                                    : "filled"
                            }
                            label="Anlagentyp"
                            size="small"
                            value={newHeatingSystem.heating_system_type}
                            onChange={(e) =>
                                setNewHeatingSystem((cond) => {
                                    cond.heating_system_type = e.target.value;
                                    cond.degree_of_efficiency = {
                                        display: createGermanNumberString(
                                            heatingSystemTypes.find((item) => item.id === e.target.value)
                                                ?.degree_of_efficiency
                                        ),
                                        real: heatingSystemTypes.find((item) => item.id === e.target.value)
                                            ?.degree_of_efficiency,
                                    };
                                })
                            }
                            disabled={!editModeBuildingHeatingSystem || newHeatingSystem.energy_source === "-"}
                            sx={{
                                width: "34ch",
                            }}
                        >
                            {heatingSystemTypes.map((item) => {
                                if (item.energy_source === newHeatingSystem.energy_source) {
                                    return (
                                        <MenuItem key={item.name} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Select>
                    </FormControl>
                    {/* ------------------------- Verbrauchstyp (nicht mehr nötig, da Lastanteile erfasst werden) ------------------------- */}
                    {/* <FormControl sx={{ mt: 1, mr: 1 }}>
                        <InputLabel
                            disabled={!editModeBuildingHeatingSystem}
                            sx={{
                                mt: !editModeBuildingHeatingSystem && newHeatingSystem.consumption_type ? 1.5 : 0,
                            }}
                        >
                            Verbrauchstyp
                        </InputLabel>
                        <Select
                            variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                            label="Verbrauchtstyp"
                            size="small"
                            value={newHeatingSystem.consumption_type}
                            onChange={(e) =>
                                setNewHeatingSystem((cond) => {
                                    cond.consumption_type = e.target.value;
                                })
                            }
                            disabled={!editModeBuildingHeatingSystem}
                            sx={{
                                width: "36ch",
                            }}
                            default={consumptionTypes[0].value}
                        >
                            {consumptionTypes.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                    {/* ------------------------- Einbaujahr ------------------------- */}
                    <ValidatedTextField
                        type="number"
                        variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                        label="Einbaujahr"
                        size="small"
                        value={newHeatingSystem.year}
                        onChange={(event) =>
                            setNewHeatingSystem((cond) => {
                                cond.year = event.target.value;
                            })
                        }
                        disabled={!editModeBuildingHeatingSystem}
                        sx={{
                            mt: 1,
                            mr: 1,
                            width: "25ch",
                        }}
                        validate={(val) => {
                            if (
                                !val ||
                                Number(val) < 1900 ||
                                Number(val) > buildingHeatingSystemsState[buildingHeatingSystemsStateTab].year
                            ) {
                                return false;
                            }
                            return true;
                        }}
                        error={errorNewHeatingSystem.year || (editModeBuildingHeatingSystem && !newHeatingSystem.year)}
                        setError={(val) => {
                            setErrorNewHeatingSystem((err) => {
                                err.year = val;
                            });
                        }}
                    />
                    {/* ------------------------- Emissionsmodell ------------------------- */}
                    <FormControl sx={{ mt: 1, mr: 1 }}>
                        <InputLabel
                            disabled={!editModeBuildingHeatingSystem}
                            sx={{
                                mt: !editModeBuildingHeatingSystem && newHeatingSystem.emission_model ? 1.5 : 0,
                            }}
                        >
                            Emissionsmodell
                        </InputLabel>
                        <Select
                            variant={
                                editModeBuildingHeatingSystem && newHeatingSystem.energy_source !== "-"
                                    ? "outlined"
                                    : "filled"
                            }
                            label="Individuelles Emissions-Modell"
                            size="small"
                            value={newHeatingSystem.emission_model}
                            onChange={(e) =>
                                setNewHeatingSystem((cond) => {
                                    cond.emission_model = e.target.value;
                                })
                            }
                            disabled={!editModeBuildingHeatingSystem || newHeatingSystem.energy_source === "-"}
                            sx={{
                                width: "36ch",
                            }}
                        >
                            {emissionModels.map((item) => {
                                if (item.energy_source === newHeatingSystem.energy_source) {
                                    return (
                                        <MenuItem key={item.name} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Select>
                    </FormControl>
                    {/* ------------------------- Lastanteil Raumwärme ------------------------- */}
                    {estimatedSharesOfConsumptionRoomheating && (
                        <IntegerInput
                            variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                            label="Lastanteil Raumwärme"
                            size="small"
                            value={newHeatingSystem.estimated_share_of_consumption_roomheating}
                            onChange={(val) => {
                                setNewHeatingSystem((cond) => {
                                    cond.estimated_share_of_consumption_roomheating = val;
                                });
                            }}
                            disabled={
                                !editModeBuildingHeatingSystem ||
                                buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems.length ===
                                    0
                            }
                            sx={{ mt: 1, mr: 1, width: "30.5ch" }}
                            validate={(val) => {
                                const closestHeatingSystemIdx =
                                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems
                                        .length - 1;
                                if (
                                    val < 0 ||
                                    val >
                                        buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                            .estimated_shares_of_consumption_roomheating[
                                            buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                .heating_systems[closestHeatingSystemIdx].id
                                        ].real
                                ) {
                                    return false;
                                }
                                return true;
                            }}
                            error={
                                errorNewHeatingSystem.estimated_shares_of_consumption_roomheating ||
                                (editModeBuildingHeatingSystem &&
                                    !newHeatingSystem.estimated_share_of_consumption_roomheating.real &&
                                    !newHeatingSystem.estimated_share_of_consumption_roomheating === 0)
                            }
                            setError={(val) => {
                                setErrorNewHeatingSystem((err) => {
                                    err.estimated_shares_of_consumption_roomheating = val;
                                });
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography sx={{ mr: 1 }}>%</Typography>
                                        <CustomTooltip
                                            title={
                                                <>
                                                    Der geschätze Anteil des Wärmebedarfs, der durch diese Anlage
                                                    bedient wird. <br />
                                                    {buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                        .heating_systems.length > 0 ? (
                                                        <>
                                                            Wichtig: Wenn Sie diesen Wert anpassen, hat dies einen
                                                            impliziten Effekt auf den Raumwärme-Lastanteil der Anlage
                                                            <b>
                                                                {" "}
                                                                A
                                                                {
                                                                    buildingHeatingSystemsState[
                                                                        [buildingHeatingSystemsStateTab]
                                                                    ].heating_systems.length
                                                                }
                                                            </b>
                                                            . Daher kann der Lastanteil dieser Anlage nicht größer sein
                                                            als die Summe des bisherigen Lastanteils plus dem aktuellen
                                                            Lastanteil der Anlage
                                                            <b>
                                                                {" "}
                                                                A
                                                                {
                                                                    buildingHeatingSystemsState[
                                                                        [buildingHeatingSystemsStateTab]
                                                                    ].heating_systems.length
                                                                }
                                                            </b>
                                                        </>
                                                    ) : null}
                                                </>
                                            }
                                        >
                                            <InfoIcon color="neutral" />
                                        </CustomTooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    {/* ------------------------- Lastanteil Warmwasser ------------------------- */}
                    {estimatedSharesOfConsumptionWaterheating && (
                        <IntegerInput
                            variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                            label="Lastanteil Warmwasser"
                            size="small"
                            value={newHeatingSystem.estimated_share_of_consumption_waterheating}
                            onChange={(val) => {
                                setNewHeatingSystem((cond) => {
                                    cond.estimated_share_of_consumption_waterheating = val;
                                });
                            }}
                            disabled={
                                !editModeBuildingHeatingSystem ||
                                buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems.length ===
                                    0
                            }
                            sx={{ mt: 1, mr: 1, ml: 0, width: "30.5ch" }}
                            validate={(val) => {
                                const closestHeatingSystemIdx =
                                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems
                                        .length - 1;
                                if (
                                    val < 0 ||
                                    val >
                                        buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                            .estimated_shares_of_consumption_waterheating[
                                            buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                .heating_systems[closestHeatingSystemIdx].id
                                        ].real
                                ) {
                                    return false;
                                }
                                return true;
                            }}
                            error={
                                errorNewHeatingSystem.estimated_shares_of_consumption_waterheating ||
                                (editModeBuildingHeatingSystem &&
                                    !newHeatingSystem.estimated_share_of_consumption_waterheating.real &&
                                    !newHeatingSystem.estimated_share_of_consumption_waterheating === 0)
                            }
                            setError={(val) => {
                                setErrorNewHeatingSystem((err) => {
                                    err.estimated_shares_of_consumption_waterheating = val;
                                });
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography sx={{ mr: 1 }}>%</Typography>
                                        <CustomTooltip
                                            title={
                                                <>
                                                    Der geschätze Anteil des Warmwasserbedarfs aus{" "}
                                                    <i>zentraler Warmwasseraufbereitung</i>, der durch diese Anlage
                                                    bedient wird. Dies hat keinen Einfluss auf die Berechnungen zur
                                                    dezentralen Warmwasseraufbereitung. <br />
                                                    {buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                        .heating_systems.length > 0 ? (
                                                        <>
                                                            Wichtig: Wenn Sie diesen Wert anpassen, hat dies einen
                                                            impliziten Effekt auf den Warmwasser-Lastanteil der Anlage
                                                            <b>
                                                                {" "}
                                                                A
                                                                {
                                                                    buildingHeatingSystemsState[
                                                                        [buildingHeatingSystemsStateTab]
                                                                    ].heating_systems.length
                                                                }
                                                            </b>
                                                            . Daher kann der Lastanteil dieser Anlage nicht größer sein
                                                            als die Summe des bisherigen Lastanteils plus dem aktuellen
                                                            Lastanteil der Anlage
                                                            <b>
                                                                {" "}
                                                                A
                                                                {
                                                                    buildingHeatingSystemsState[
                                                                        [buildingHeatingSystemsStateTab]
                                                                    ].heating_systems.length
                                                                }
                                                            </b>
                                                        </>
                                                    ) : null}
                                                </>
                                            }
                                        >
                                            <InfoIcon color="neutral" />
                                        </CustomTooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    {/* ------------------------- Standard Jahresnutzungsgrad der Heizungsanlage (in allen zugehörigen Heizungsanlagen-Zuständen) ------------------------- */}
                    <FloatInput
                        variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                        label="Standard-Jahresnutzungsgrad"
                        size="small"
                        value={newHeatingSystem.degree_of_efficiency}
                        onChange={(val) =>
                            setNewHeatingSystem((cond) => {
                                cond.degree_of_efficiency = val;
                            })
                        }
                        disabled={!editModeBuildingHeatingSystem}
                        sx={{ mt: 1, mr: 1, ml: 0, width: "28ch" }}
                        validate={(val) => {
                            return true;
                        }}
                        error={errorNewHeatingSystem.degree_of_efficiency}
                        setError={(val) => {
                            setErrorNewHeatingSystem((err) => {
                                err.degree_of_efficiency = val;
                            });
                        }}
                    />
                    {/* ------------------------- Individueller Jahresnutzungsgrad der Anlage in diesem Heizungsanlagen-Zustand ------------------------- */}
                    <FloatInput
                        variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                        label="Abweichender Jahresnutzungsgrad"
                        size="small"
                        value={newHeatingSystem.individual_degree_of_efficiency}
                        onChange={(val) =>
                            setNewHeatingSystem((cond) => {
                                cond.individual_degree_of_efficiency = val;
                            })
                        }
                        disabled={!editModeBuildingHeatingSystem}
                        sx={{ mt: 1, mr: 1, ml: 0, width: "33ch" }}
                        validate={(val) => {
                            return true;
                        }}
                        error={errorNewHeatingSystem.degree_of_efficiency}
                        setError={(val) => {
                            setErrorNewHeatingSystem((err) => {
                                err.degree_of_efficiency = val;
                            });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CustomTooltip title="Falls in einem bestimmten Zeitraum der Jahresnutzungsgrad der Anlage vom Standard-Jahresnutzungsgrad abweicht, kann dies hier angegeben werden. Der abweichende Wert wird für alle Jahresprognosen einbezogen, die auf diesem Wärmeversorgungsanlagen-Zustand basieren.">
                                        <InfoIcon color="neutral" />
                                    </CustomTooltip>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {/* ------------------------- Anmerkung ------------------------- */}
                    <TextField
                        variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                        label="Anmerkung zur Wärmeversorgungsanlage"
                        value={newHeatingSystem.note}
                        onChange={(e) =>
                            setNewHeatingSystem((cond) => {
                                cond.note = e.target.value;
                            })
                        }
                        disabled={!editModeBuildingHeatingSystem}
                        multiline
                        rows={2}
                        sx={{ mt: 1, mr: 1, ml: 0, width: "62ch" }}
                    />
                </div>
            </CustomAccordionDetails>
            <CustomAccordionActions>
                {/* ------------------------- Bearbeiten & Anlage hinzufügen Buttons ------------------------- */}
                <Fab
                    variant="extended"
                    size="medium"
                    color={editModeBuildingHeatingSystem ? "default" : "secondary"}
                    onClick={() => {
                        handleEditMode();
                    }}
                    sx={{ zIndex: 0, mr: 1, boxShadow: 1, borderRadius: 1 }}
                >
                    {editModeBuildingHeatingSystem ? <></> : <EditIcon sx={{ mr: 1 }} />}
                    {editModeBuildingHeatingSystem ? "Abbrechen" : "Bearbeiten"}
                </Fab>
                {editModeBuildingHeatingSystem && (
                    <Fab
                        disabled={
                            (Object.keys(errorNewHeatingSystem).length > 0 &&
                                !Object.values(errorNewHeatingSystem).every((item) => item === false)) ||
                            newHeatingSystem.energy_source === "-" ||
                            newHeatingSystem.year === "" ||
                            newHeatingSystem.heating_system_type === "" ||
                            // newHeatingSystem.consumption_type === "" ||
                            newHeatingSystem.degree_of_efficiency.real === null ||
                            newHeatingSystem.degree_of_efficiency.real === ""
                        }
                        variant="extended"
                        size="medium"
                        color={editModeBuildingHeatingSystem ? "secondary" : "default"}
                        onClick={() => {
                            handleSave({
                                heatingSystem: newHeatingSystem,
                                heatingSystemIdx: null,
                                newHeatingSystem: true,
                            });
                            setNewHeatingSystem(emptyHeatingSystem);
                        }}
                        sx={{ zIndex: 0, mr: 1, boxShadow: 1, borderRadius: 1 }}
                    >
                        <SaveIcon sx={{ mr: 1 }} />
                        Anlage hinzufügen
                    </Fab>
                )}
            </CustomAccordionActions>
        </CustomAccordion>
    );
};
