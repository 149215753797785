// ResultBarGauges.js
// Author: Lukas Aldenhoff 2023, lukas.aldenhoff@gmail.com
import { React, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Scale from "./Scale";

/**
 * Renders a consumption scale component based on energy efficiency classes.
 *
 * This component displays a scale representing different energy efficiency classes. It marks the
 * consumption level on the scale and adjusts the scale based on the provided energy efficiency classes.
 * The scale is dynamically generated based on the energy efficiency class intervals.
 *
 * @param {Object} props - The component props.
 * @param {number} props.consumptionMarker - The consumption value to be marked on the scale.
 * @param {Array} props.energyEfficiencyClasses - An array of energy efficiency classes, each with interval start, end, and name.
 * @param {number} props.heightScales - The height scaling factor for the component.
 * @returns {JSX.Element} A box containing the consumption scale.
 *
 * @example
 * const energyEfficiencyClasses = [
 *   { interval_start: 0, interval_end: 50, name: 'A' },
 *   { interval_start: 51, interval_end: 100, name: 'B' },
 *   // ... more classes
 * ];
 * <ConsumptionScale
 *   consumptionMarker={75}
 *   energyEfficiencyClasses={energyEfficiencyClasses}
 *   heightScales={1}
 * />
 */
export const ConsumptionScale = ({ consumptionMarker, energyEfficiencyClasses, heightScales }) => {
    const [energyEfficiencyClassesScaleMarks, setEnergyEfficiencyClassesScaleMarks] = useState([0]);

    useEffect(() => {
        setEnergyEfficiencyClassesScaleMarks(getEnergyScaleMarks(energyEfficiencyClasses));
    }, []);

    function getEnergyScaleMarks(arr) {
        var marksArray = [];
        arr.forEach((element, index) => {
            if (index === 0) {
                marksArray.push(0);
            }
            if (index === arr.length - 1) {
                marksArray.push(
                    Number(element.interval_start) * 1.2 // the last interval has no "end", but 20% above the start works decently
                );
            } else {
                marksArray.push(Number(element.interval_end));
            }
        });
        return marksArray;
    }

    return (
        <Box height={String(heightScales * 100) + "px"} width="100%" mt="10px">
            <Scale
                marginLeft={120}
                marginRight={10}
                data={{
                    measures: [0],
                    ranges: energyEfficiencyClassesScaleMarks,
                    markers: [
                        Math.min(
                            Number(consumptionMarker),
                            energyEfficiencyClassesScaleMarks[energyEfficiencyClassesScaleMarks.length - 1]
                        ),
                    ],
                    labels: energyEfficiencyClasses.map((elem) => elem.name),
                    id: "ErwarteterEndenergieVerbrauch",
                    title: (
                        <text x={0} dy={-20}>
                            <tspan
                                style={{
                                    fill: "#000",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                            >
                                Erwarteter
                            </tspan>
                            <tspan
                                x={0}
                                dy={18}
                                style={{
                                    fill: "#000",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                            >
                                Endenergie-
                            </tspan>
                            <tspan
                                x={0}
                                dy={18}
                                style={{
                                    fill: "#000",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                            >
                                verbrauch
                            </tspan>
                            <tspan
                                x={15}
                                dy={20}
                                style={{
                                    fill: "#999",
                                    fontSize: "12px",
                                }}
                            >
                                {"kWh/(m\u00B2\u2217a)"}
                            </tspan>
                        </text>
                    ),
                }}
            />
        </Box>
    );
};

/**
 * Renders bar gauges for energy efficiency and CO2 emissions based on user results.
 *
 * This component displays two bar gauges: one for energy efficiency and another for CO2 emissions.
 * The gauges are based on the latest user results and are scaled according to the provided
 * energy efficiency and CO2 emission classes. The component dynamically generates the scales
 * and markers for the gauges based on the user results and class intervals.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.userResults - An array of user result objects, each containing data for a specific year.
 * @param {Array} props.energyEfficiencyClasses - An array of energy efficiency classes, each with interval start, end, and name.
 * @param {Array} props.co2EmissionClasses - An array of CO2 emission classes, each with interval start, end, and name.
 * @param {number} props.heightScales - The height scaling factor for the component.
 * @returns {JSX.Element} A grid containing two boxes, each with a scale for energy efficiency and CO2 emissions.
 *
 * @example
 * const userResults = [
 *   { year: 2021, total_gross_energy_consumption_per_sqm: 120, total_co2_emissions_per_sqm: 30, ... },
 *   // ... more results
 * ];
 * const energyEfficiencyClasses = [
 *   { interval_start: 0, interval_end: 50, name: 'A' },
 *   // ... more classes
 * ];
 * const co2EmissionClasses = [
 *   { emission_interval_start: 0, emission_interval_end: 10, name: '1' },
 *   // ... more classes
 * ];
 * <ResultBarGauges
 *   userResults={userResults}
 *   energyEfficiencyClasses={energyEfficiencyClasses}
 *   co2EmissionClasses={co2EmissionClasses}
 *   heightScales={1}
 * />
 */
const ResultBarGauges = ({ userResults, energyEfficiencyClasses, co2EmissionClasses, heightScales }) => {
    const [energyEfficiencyClassesScaleMarks, setEnergyEfficiencyClassesScaleMarks] = useState([0]);
    const [co2EmissionClassesScaleMarks, setCo2EmissionClassesScaleMarks] = useState([0]);

    useEffect(() => {
        setEnergyEfficiencyClassesScaleMarks(getEnergyScaleMarks(energyEfficiencyClasses));
        setCo2EmissionClassesScaleMarks(getEmissionScaleMarks(co2EmissionClasses));
    }, []);

    function getEnergyScaleMarks(arr) {
        var marksArray = [];
        arr.forEach((element, index) => {
            if (index === 0) {
                marksArray.push(0);
            }
            if (index === arr.length - 1) {
                marksArray.push(
                    Number(element.interval_start) * 1.2 // the last interval has no "end", but 20% above the start works decently
                );
            } else {
                marksArray.push(Number(element.interval_end));
            }
        });
        return marksArray;
    }

    function getEmissionScaleMarks(arr) {
        var marksArray = [];
        arr.forEach((element, index) => {
            if (index === 0) {
                marksArray.push(0);
            }
            if (index === arr.length - 1) {
                marksArray.push(Number(element.emission_interval_start) * 1.2); // the last interval has no "end", but 20% above the start works decently
            } else {
                marksArray.push(Number(element.emission_interval_end));
            }
        });
        return marksArray;
    }

    function getMarkersGrossEnergy() {
        var markersArray = [
            Math.min(
                userResults[userResults.length - 1].total_gross_energy_consumption_per_sqm,
                energyEfficiencyClassesScaleMarks[energyEfficiencyClassesScaleMarks.length - 1]
            ),
        ];
        if (userResults[userResults.length - 1].total_pv_yield_per_sqm) {
            markersArray.push(
                Math.min(
                    userResults[userResults.length - 1].total_pv_yield_per_sqm,
                    energyEfficiencyClassesScaleMarks[energyEfficiencyClassesScaleMarks.length - 1]
                )
            );
        } else markersArray.push(0);
        if (userResults[userResults.length - 1].energy_certificate_value) {
            markersArray.push(
                Math.min(
                    userResults[userResults.length - 1].energy_certificate_value,
                    energyEfficiencyClassesScaleMarks[energyEfficiencyClassesScaleMarks.length - 1]
                )
            );
        } else markersArray.push(0);
        return markersArray;
    }

    function getMarkersCo2Emissions() {
        var markersArray = [
            Math.min(
                userResults[userResults.length - 1].total_co2_emissions_per_sqm,
                co2EmissionClassesScaleMarks[co2EmissionClassesScaleMarks.length - 1]
            ),
        ];
        if (userResults[userResults.length - 1].total_pv_co2_savings_per_sqm) {
            markersArray.push(
                Math.min(
                    userResults[userResults.length - 1].total_pv_co2_savings_per_sqm,
                    co2EmissionClassesScaleMarks[co2EmissionClassesScaleMarks.length - 1]
                )
            );
        }
        return markersArray;
    }

    return (
        <Grid container>
            <Box height={String(heightScales * 100) + "px"} width="48%" mt="30px">
                <Scale
                    marginLeft={130}
                    data={{
                        measures: [0],
                        ranges: energyEfficiencyClassesScaleMarks,
                        markers: getMarkersGrossEnergy(),
                        labels: energyEfficiencyClasses.map((elem) => elem.name),
                        id: "Energieeffizienz",
                        title: (
                            <text dy={-12}>
                                <tspan
                                    style={{
                                        fill: "#000",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                    }}
                                >
                                    Energieeffizienz
                                </tspan>
                                <tspan
                                    x={75}
                                    dy={18}
                                    style={{
                                        fill: "#999",
                                        fontSize: "14px",
                                    }}
                                >
                                    {String(userResults[userResults.length - 1].year)}
                                </tspan>
                                <tspan
                                    x={45}
                                    dy={20}
                                    style={{
                                        fill: "#999",
                                        fontSize: "12px",
                                    }}
                                >
                                    {"kWh/(m\u00B2\u2217a)"}
                                </tspan>
                            </text>
                        ),
                    }}
                />
            </Box>

            <Box height={String(heightScales * 100) + "px"} width="48%" mt="30px">
                <Scale
                    marginLeft={130}
                    data={{
                        measures: [0],
                        ranges: co2EmissionClassesScaleMarks,
                        markers: getMarkersCo2Emissions(),
                        labels: co2EmissionClasses.map((elem, index) => String(index + 1)),
                        id: "co2emissions",
                        title: (
                            <text dy={-12}>
                                <tspan
                                    style={{
                                        fill: "#000",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                    }}
                                >
                                    {"CO\u2082 Emissionen"}
                                </tspan>
                                <tspan
                                    x={77}
                                    dy={18}
                                    style={{
                                        fill: "#999",
                                        fontSize: "14px",
                                    }}
                                >
                                    {String(userResults[userResults.length - 1].year)}
                                </tspan>
                                <tspan
                                    x={56}
                                    dy={20}
                                    style={{
                                        fill: "#999",
                                        fontSize: "12px",
                                    }}
                                >
                                    {"kg/(m\u00B2\u2217a)"}
                                </tspan>
                            </text>
                        ),
                    }}
                />
            </Box>
        </Grid>
    );
};

export default ResultBarGauges;
