import React from "react";
import { Container, Typography, Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import logger from "../../services/Logging";
import { Loglevel } from "../../services/Logging";

/**
 * A React component that acts as an error boundary to catch JavaScript errors in its child component tree.
 *
 * This class component encapsulates its children and checks for any JavaScript errors that occur in them.
 * If an error is caught, it renders a fallback UI instead of the component tree that crashed, providing
 * a better user experience and preventing the entire application from breaking.
 *
 * Usage:
 * Wrap any component or component tree with `ErrorBoundary` to handle errors gracefully.
 *
 * Example:
 * ```
 * <ErrorBoundary>
 *   <YourComponent />
 * </ErrorBoundary>
 * ```
 *
 * Props:
 *   children (ReactNode): The child components that are being monitored for JavaScript errors.
 *
 * Returns:
 *   ReactNode: Either the child components if no error occurs, or a fallback UI in case of an error.
 */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        logger.log(Loglevel.ERROR, "Uncaught error in Frontend.", errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Container maxWidth="sm">
                    <Box
                        sx={{
                            textAlign: "center",
                            padding: 4,
                        }}
                    >
                        <ErrorOutlineIcon sx={{ fontSize: 60, color: "error.main" }} />
                        <Typography variant="h4" gutterBottom>
                            Etwas ist schiefgelaufen.
                        </Typography>
                        <Typography>Bitte lade die Seite neu oder versuche es später noch einmal.</Typography>
                        <Typography>
                            Falls das Problem bestehen bleibt, melde dich bei unserem Support info@advisore.eu mit einer
                            kurzen Beschreibung des Fehlers.
                        </Typography>
                    </Box>
                </Container>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
