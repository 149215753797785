import api_client from "./http-common";

/**
 * Asynchronously fetches user results from the backend API.
 *
 * This function sends a POST request to the `/user-results/` endpoint with filter parameters.
 * It retrieves and processes data related to user results, energy efficiency classes, and CO2 emission classes.
 *
 * Parameters:
 * - filter_type: A string indicating the type of filter to apply (e.g., 'all', 'best', 'worst').
 * - filter_choice: An array or other structure representing the specific filter choices (e.g., number of buildings).
 *
 * Returns:
 *   Object: An object containing arrays of user results, energy efficiency classes, and CO2 emission classes.
 */
export default async function fetchUserResults({ filter_type, filter_choice }) {
    const result = await api_client.post(`/user-results/`, {
        filter_type,
        filter_choice,
    });
    var userResultsArr = await result.data.results;
    var energyEfficiencyClassesArr = await result.data.energy_efficiency_classes;
    var co2EmissionClassesArr = await result.data.co2_emission_classes;
    return {
        userResults: userResultsArr,
        energyEfficiencyClasses: energyEfficiencyClassesArr,
        co2EmissionClasses: co2EmissionClassesArr,
    };
}

export const ResultDataTypes = {
    Co2Emissions: "Co2Emissions",
    Co2EmissionsPerSqm: "Co2EmissionsPerSqm",
    GrossEnergyConsumption: "GrossEnergyConsumption",
    GrossEnergyConsumptionPerSqm: "GrossEnergyConsumptionPerSqm",
    SingleBuildingCo2Emissions: "SingleBuildingCo2Emissions",
    SingleBuildingCo2EmissionsPerSqm: "SingleBuildingCo2EmissionsPerSqm",
    SingleBuildingGrossEnergyConsumption: "SingleBuildingGrossEnergyConsumption",
    SingleBuildingGrossEnergyConsumptionPerSqm: "SingleBuildingGrossEnergyConsumptionPerSqm",
};

/**
 * Generates table data from user results based on the specified data type.
 *
 * This function processes user results to create a structured array of data suitable for display.
 * It supports different data types, each producing a specific structure of data.
 *
 * Parameters:
 * - datatype: A ResultDataTypes enum value specifying the type of data to generate.
 * - userResults: An array of user result objects to process.
 * - climateCleaned: A boolean indicating whether to apply climate factor adjustments to the data (default: false).
 *
 * Returns:
 *   Array: An array of objects, each representing a row of data for the table.
 *
 * Note:
 * - The function handles various data types like CO2 emissions, energy consumption, etc., for both total and per square meter calculations.
 * - For 'SingleBuilding' data types, it differentiates between actual data and forecast data.
 * - If the resulting data array has less than 10 items, it pads the array with empty data up to 10 items.
 */
export function getTableDataFromUserResults(datatype, userResults, climateCleaned = false) {
    var dataArray = [];
    switch (datatype) {
        case ResultDataTypes.Co2Emissions:
            dataArray = userResults.map((data) => {
                return {
                    year: data.year,
                    "Raumwärme (Verbräuche)": Math.round(
                        data.total_co2_emissions_roomheating *
                            (climateCleaned ? data.weighted_climate_factor_co2_emissions_roomheating : 1)
                    ),
                    "Warmwasser (Verbräuche)": data.total_co2_emissions_waterheating,
                    Solar: -data.total_pv_co2_savings,
                    "Raumwärme (Prognose)": Math.round(
                        data.forecast_total_co2_emissions_roomheating *
                            (climateCleaned ? data.weighted_climate_factor_co2_emissions_roomheating : 1)
                    ),
                    "Warmwasser (Prognose)": data.forecast_total_co2_emissions_waterheating,
                };
            });
            break;

        case ResultDataTypes.Co2EmissionsPerSqm:
            dataArray = userResults.map((data) => {
                return {
                    year: data.year,
                    "Raumwärme (Verbräuche)": (
                        data.mixed_data_total_co2_emissions_roomheating_per_sqm *
                        (climateCleaned ? data.weighted_climate_factor_co2_emissions_roomheating : 1)
                    ).toFixed(1),
                    "Warmwasser (Verbräuche)": data.mixed_data_total_co2_emissions_waterheating_per_sqm,
                    Solar: -data.total_pv_co2_savings_per_sqm,
                    "Raumwärme (Prognose)": (
                        data.forecast_total_co2_emissions_roomheating_per_sqm *
                        (climateCleaned ? data.weighted_climate_factor_co2_emissions_roomheating : 1)
                    ).toFixed(1),
                    "Warmwasser (Prognose)": data.forecast_total_co2_emissions_waterheating_per_sqm,
                };
            });
            break;

        case ResultDataTypes.GrossEnergyConsumption:
            dataArray = userResults.map((data) => {
                return {
                    year: data.year,
                    "Raumwärme (Verbräuche)": Math.round(
                        data.total_gross_energy_consumption_roomheating *
                            (climateCleaned ? data.weighted_climate_factor_consumption_roomheating : 1)
                    ),
                    "Warmwasser (Verbräuche)": data.total_gross_energy_consumption_waterheating,
                    Solar: -data.total_pv_yield,
                    "Raumwärme (Prognose)": Math.round(
                        data.forecast_total_gross_energy_consumption_roomheating *
                            (climateCleaned ? data.weighted_climate_factor_consumption_roomheating : 1)
                    ),
                    "Warmwasser (Prognose)": data.forecast_total_gross_energy_consumption_waterheating,
                };
            });
            break;

        case ResultDataTypes.GrossEnergyConsumptionPerSqm:
            dataArray = userResults.map((data) => {
                return {
                    year: data.year,
                    "Raumwärme (Verbräuche)": (
                        data.mixed_data_total_gross_energy_consumption_roomheating_per_sqm *
                        (climateCleaned ? data.weighted_climate_factor_consumption_roomheating : 1)
                    ).toFixed(1),
                    "Warmwasser (Verbräuche)": data.mixed_data_total_gross_energy_consumption_waterheating_per_sqm,
                    Solar: -data.total_pv_yield_per_sqm,
                    "Raumwärme (Prognose)": (
                        data.forecast_total_gross_energy_consumption_roomheating_per_sqm *
                        (climateCleaned ? data.weighted_climate_factor_consumption_roomheating : 1)
                    ).toFixed(1),
                    "Warmwasser (Prognose)": data.forecast_total_gross_energy_consumption_waterheating_per_sqm,
                };
            });
            break;

        case ResultDataTypes.SingleBuildingCo2Emissions:
            dataArray = userResults.map((data) => {
                if (data.only_forecast_data) {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": 0,
                        "Warmwasser (Verbräuche)": 0,
                        Solar: -data.total_pv_co2_savings,
                        "Raumwärme (Prognose)": Math.round(
                            data.total_co2_emissions_roomheating * (climateCleaned ? data.climate_factor : 1)
                        ),
                        "Warmwasser (Prognose)": data.total_co2_emissions_waterheating,
                    };
                } else {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": Math.round(
                            data.total_co2_emissions_roomheating * (climateCleaned ? data.climate_factor : 1)
                        ),
                        "Warmwasser (Verbräuche)": data.total_co2_emissions_waterheating,
                        Solar: -data.total_pv_co2_savings,
                        "Raumwärme (Prognose)": 0,
                        "Warmwasser (Prognose)": 0,
                    };
                }
            });
            break;

        case ResultDataTypes.SingleBuildingCo2EmissionsPerSqm:
            dataArray = userResults.map((data) => {
                if (data.only_forecast_data) {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": 0,
                        "Warmwasser (Verbräuche)": 0,
                        Solar: -data.total_pv_co2_savings_per_sqm,
                        "Raumwärme (Prognose)": (
                            data.total_co2_emissions_roomheating_per_sqm * (climateCleaned ? data.climate_factor : 1)
                        ).toFixed(1),
                        "Warmwasser (Prognose)": data.total_co2_emissions_waterheating_per_sqm,
                    };
                } else {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": (
                            data.total_co2_emissions_roomheating_per_sqm * (climateCleaned ? data.climate_factor : 1)
                        ).toFixed(1),
                        "Warmwasser (Verbräuche)": data.total_co2_emissions_waterheating_per_sqm,
                        Solar: -data.total_pv_co2_savings_per_sqm,
                        "Raumwärme (Prognose)": 0,
                        "Warmwasser (Prognose)": 0,
                    };
                }
            });
            break;

        case ResultDataTypes.SingleBuildingGrossEnergyConsumption:
            dataArray = userResults.map((data) => {
                if (data.only_forecast_data) {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": 0,
                        "Warmwasser (Verbräuche)": 0,
                        Solar: -data.total_pv_yield,
                        "Raumwärme (Prognose)": Math.round(
                            data.total_gross_energy_consumption_roomheating * (climateCleaned ? data.climate_factor : 1)
                        ),
                        "Warmwasser (Prognose)": data.total_gross_energy_consumption_waterheating,
                    };
                } else {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": Math.round(
                            data.total_gross_energy_consumption_roomheating * (climateCleaned ? data.climate_factor : 1)
                        ),
                        "Warmwasser (Verbräuche)": data.total_gross_energy_consumption_waterheating,
                        Solar: -data.total_pv_yield,
                        "Raumwärme (Prognose)": 0,
                        "Warmwasser (Prognose)": 0,
                    };
                }
            });
            break;

        case ResultDataTypes.SingleBuildingGrossEnergyConsumptionPerSqm:
            dataArray = userResults.map((data) => {
                if (data.only_forecast_data) {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": 0,
                        "Warmwasser (Verbräuche)": 0,
                        Solar: -data.total_pv_yield_per_sqm,
                        "Raumwärme (Prognose)": (
                            data.total_gross_energy_consumption_roomheating_per_sqm *
                            (climateCleaned ? data.climate_factor : 1)
                        ).toFixed(1),
                        "Warmwasser (Prognose)": data.total_gross_energy_consumption_waterheating_per_sqm,
                    };
                } else {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": (
                            data.total_gross_energy_consumption_roomheating_per_sqm *
                            (climateCleaned ? data.climate_factor : 1)
                        ).toFixed(1),
                        "Warmwasser (Verbräuche)": data.total_gross_energy_consumption_waterheating_per_sqm,
                        Solar: -data.total_pv_yield_per_sqm,
                        "Raumwärme (Prognose)": 0,
                        "Warmwasser (Prognose)": 0,
                    };
                }
            });
            break;

        default:
            break;
    }
    if (dataArray.length < 10) {
        for (var i = dataArray.length; i < 10; i++) {
            dataArray.push({ year: dataArray[dataArray.length - 1].year + 1, Raumwärme: 0, Warmwasser: 0 });
        }
    }
    return dataArray;
}
