import React, { useState, useContext } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Divider, Modal, Fab } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import api_client from "../../services/http-common";
import { ValidatedTextField } from "../../components/InputFields";
import AuthContext from "../../contexts/AuthContext";
import ToastContext from "../../contexts/ToastContext";

const Settings = () => {
    const { loginUser } = useContext(AuthContext);

    const { addToastMessage } = useContext(ToastContext);

    const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [currentPasswordErrorText, setCurrentPasswordErrorText] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [newPasswordErrorText, setNewPasswordErrorText] = useState("");

    const handlePasswordChangeModalOpen = () => {
        setPasswordChangeModalOpen(true);
    };

    const handlePasswordChangeModalClose = () => {
        // Reset the form
        setCurrentPassword("");
        setNewPassword("");
        setCurrentPasswordError(false);
        setNewPasswordError(false);
        // Close the modal
        setPasswordChangeModalOpen(false);
    };

    const handlePasswordChange = () => {
        api_client
            .post(`/password/change/`, { current_password: currentPassword, new_password: newPassword })
            .then((response) => {
                if (response && response.status) {
                    handlePasswordChangeModalClose();
                }
                if (
                    loginUser(
                        localStorage.getItem("username"),
                        newPassword,
                        () => {
                            return null;
                        },
                        () => {
                            return null;
                        }
                    )
                ) {
                    addToastMessage(
                        "Passwort erfolgreich geändert.",
                        "success",
                        true,
                        6000,
                        "toast-password-change-success"
                    );
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    addToastMessage(
                        "Fehler beim Ändern des Passworts.",
                        "error",
                        true,
                        6000,
                        "toast-password-change-error"
                    );
                    if (error.response.data.current_password) {
                        setCurrentPasswordError(true);
                        setCurrentPasswordErrorText("Angabe zum aktuellen Passwort is fehlerhaft.");
                    }
                    if (error.response.data.new_password) {
                        setNewPasswordError(true);
                        if (error.response.data.new_password.includes("This password is too common.")) {
                            setNewPasswordErrorText("Dieses Passwort ist zu üblich.");
                        } else if (
                            error.response.data.new_password.includes(
                                "The password is too similar to the E-Mail-Adresse."
                            )
                        ) {
                            setNewPasswordErrorText("Dieses Passwort ist zu ähnlich zur gespeicherten E-Mail Adresse.");
                        } else if (
                            error.response.data.new_password.includes(
                                "The password is too similar to the Benutzername."
                            )
                        ) {
                            setNewPasswordErrorText("Dieses Passwort ist zu ähnlich zum gespeicherten Benutzernamen.");
                        } else {
                            setNewPasswordErrorText("Dieses Passwort entspricht nicht unseren Passwort-Vorgaben.");
                        }
                    }
                } else {
                    handlePasswordChangeModalClose();
                }
            });
    };

    const isPasswordValid = () => {
        // MinimumLengthValidator (minimum 8 characters)
        const hasMinimumLength = newPassword.length >= 8;

        // NumericPasswordValidator (must contain at least one digit)
        const hasNumericCharacter = /\d/.test(newPassword);

        if (!hasNumericCharacter) {
            setNewPasswordErrorText("Das Password muss mindestens eine Zahl enthalten.");
            return false;
        }
        if (!hasMinimumLength) {
            setNewPasswordErrorText("Das Password muss mindestens 8 Zeichen lang sein.");
            return false;
        }

        return true;
    };

    return (
        <>
            {/* Add the "Nutzerdaten" accordion */}
            <Box sx={{ mt: 3 }}>
                <Accordion sx={{ width: "160ch" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h4">
                            <SettingsIcon sx={{ mr: 2, mb: -1.1, fontSize: 35 }} />
                            Nutzerdaten
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ width: "100%", height: 100 }}>
                            <Divider sx={{ mb: 2, mt: -2, width: "100%" }} />
                            <Fab
                                sx={{ ml: 2, boxShadow: 1, borderRadius: 1 }}
                                variant="extended"
                                color="default"
                                onClick={handlePasswordChangeModalOpen}
                            >
                                <EditIcon /> Passwort ändern
                            </Fab>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

            {/* Modal for password change */}
            <Modal open={passwordChangeModalOpen} onClose={handlePasswordChangeModalClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        outline: "none",
                        width: "330px",
                    }}
                >
                    <Typography variant="h5" sx={{ mb: 3 }}>
                        Passwort ändern
                    </Typography>
                    <ValidatedTextField
                        type="password"
                        label="Aktuelles Passwort"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        fullWidth
                        sx={{ mb: 2 }}
                        validate={(val) => {
                            return true;
                        }}
                        error={currentPasswordError}
                        setError={(val) => {
                            setCurrentPasswordError(val);
                        }}
                        errorText={currentPasswordErrorText}
                    />
                    <ValidatedTextField
                        type="password"
                        label="Neues Passwort"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        fullWidth
                        sx={{ mb: 3 }}
                        validate={(val) => {
                            return isPasswordValid(val);
                        }}
                        error={newPasswordError}
                        setError={(val) => {
                            setNewPasswordError(val);
                        }}
                        errorText={newPasswordErrorText}
                    />
                    <Fab
                        variant="extended"
                        color="default"
                        sx={{ boxShadow: 1, borderRadius: 1 }}
                        onClick={handlePasswordChangeModalClose}
                    >
                        Abbrechen
                    </Fab>
                    <Fab
                        variant="extended"
                        color="secondary"
                        onClick={handlePasswordChange}
                        sx={{ ml: 3, boxShadow: 1, borderRadius: 1 }}
                        disabled={
                            newPasswordError ||
                            currentPasswordError ||
                            newPassword.length === 0 ||
                            currentPassword.length === 0
                        }
                    >
                        <SaveIcon /> Speichern
                    </Fab>
                </Box>
            </Modal>
        </>
    );
};

export default Settings;
